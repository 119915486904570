import type {
  GetApiAdminIntacctCompanyInfoResponse,
  GetApiAdminSyncStatusResponse,
} from '@sit/client-shared';
import {
  type UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { send } from '../helpers/requests';

async function fetchSyncStatus(
  companyTreeSettingsId: number,
  isInitial = true,
) {
  const res: GetApiAdminSyncStatusResponse = await send({
    url: `/admin/sync-status/${companyTreeSettingsId}?isInitial=${isInitial}`,
    method: 'GET',
    credentials: 'include',
  });
  return res;
}

export const useQuerySyncStatus = (
  companyTreeSettingsId: number,
  isInitial?: boolean,
  options?:
    | (Omit<
        UseQueryOptions<
          Awaited<ReturnType<typeof fetchSyncStatus>>,
          unknown,
          Awaited<ReturnType<typeof fetchSyncStatus>>,
          (string | number)[]
        >,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) =>
  useQuery(
    ['sync-status', companyTreeSettingsId],
    () => fetchSyncStatus(companyTreeSettingsId, isInitial),
    options,
  );

async function updateUserPrimaryCompany(userId: string, companyId: string) {
  await send({
    url: `/admin/users/${userId}/company`,
    method: 'PUT',
    data: { companyId },
  });
  return { success: true } as const;
}

export const useMutateUserPrimaryCompany = () =>
  useMutation<
    { success: true },
    unknown,
    { userId: string; companyId: string }
  >(({ companyId, userId }) => updateUserPrimaryCompany(userId, companyId));

async function getIntacctCompanyInfo(intacctCompanyId: string) {
  const res: GetApiAdminIntacctCompanyInfoResponse = await send({
    url: `/admin/intacct-company-info/${intacctCompanyId}`,
    method: 'GET',
    credentials: 'include',
  });
  return res;
}

export const useQueryIntacctCompanyInfo = (
  intacctCompanyId: string,
  options?:
    | (Omit<
        UseQueryOptions<
          GetApiAdminIntacctCompanyInfoResponse,
          unknown,
          GetApiAdminIntacctCompanyInfoResponse,
          string[]
        >,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) =>
  useQuery(
    ['intacct-company-info', intacctCompanyId],
    () => getIntacctCompanyInfo(intacctCompanyId),
    options,
  );
