import { getProgress } from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box';
import Message from 'carbon-react/lib/components/message';
import { addMinutes } from 'date-fns';
import { useRef, useState } from 'react';
import { useQuerySyncStatus } from '../../api/admin';
import { ProgressBar } from '../../components/ProgressBar';
import Translate from '../../components/Translate/Translate';
import { Sequence } from './Sequence';
import { Steps } from './steps';

export interface Step1SyncProps {
  entityIds: string[];
  companyTreeSettingsId: number;
  onSuccess: () => void;
}

function Step1Sync({ companyTreeSettingsId, onSuccess }: Step1SyncProps) {
  const [error, setError] = useState<any>();
  const [attempts, setAttempts] = useState(0);
  const [previousProgress, setPreviousProgress] = useState<number>(0);
  const nowRef = useRef(Date.now());

  const { data: syncStatusData } = useQuerySyncStatus(
    companyTreeSettingsId,
    true,
    {
      onSuccess: (data) => {
        const newProgress = getProgress(data);
        if (newProgress === 100) {
          onSuccess();
          return;
        }
        if (attempts >= 300) {
          setError('Job timed out');
          return false;
        }
        if (previousProgress === newProgress) {
          setAttempts((prevAttempts) => prevAttempts + 1);
        } else {
          setAttempts(0);
        }
        if (newProgress) setPreviousProgress(newProgress);
      },
      refetchInterval: (data) => {
        if (!data) return false;
        if (getProgress(data) === 100) {
          return false;
        }
        return 2500;
      },
      retry: (failureCount, error) => {
        if (!(error instanceof Error)) {
          return false;
        }
        if (error.message?.includes('Too many requests') || failureCount < 5) {
          return true;
        }
        setError(error.message);
        return false;
      },
    },
  );

  const progress = syncStatusData ? getProgress(syncStatusData) : 0;
  const approximateCompletionTime = addMinutes(
    nowRef.current,
    5 * (1 - progress / 100),
  );

  return (
    <div className="provision">
      <div className="card">
        <Box pb={4}>
          <Sequence step={Steps.SYNC} />
        </Box>
        <div className="subtitle">
          <Translate scope="intacct.Werecurrentlyconnecting" />
        </div>
        <ProgressBar
          now={progress}
          approximateCompletionTime={approximateCompletionTime}
        />
        {!!error && (
          <Box mt="10px">
            <Message variant="error">{error}</Message>
          </Box>
        )}
      </div>
    </div>
  );
}

export default Step1Sync;
