import Loader from 'carbon-react/lib/components/loader';
import styled from 'styled-components';
import Translate from '../Translate/Translate';

const CustomSpinner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

interface SpinnerProps {
  size?: 'small' | 'medium' | 'large';
  text?: string;
}

const Spinner = ({ size = 'large', text }: SpinnerProps) => {
  return (
    <CustomSpinner>
      {text ? <span>{text}</span> : <Translate scope="Loading" />}
      <Loader isActive size={size} />
    </CustomSpinner>
  );
};

export default Spinner;
