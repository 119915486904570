import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Form from 'carbon-react/lib/components/form';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import Textbox from 'carbon-react/lib/components/textbox';
import I18njs from 'i18n-js';
import { type FormEventHandler, useState } from 'react';
import styled from 'styled-components';
import Translate from '../../../components/Translate/Translate';
import { send } from '../../../helpers/requests';
import toastr from '../../../helpers/toastr';

const FullWidthButton = styled(Button)`
  width: 100%;
`;

export interface UpdateWSUFormProps {
  intacctCompanyId: string;
}

export function UpdateWSUForm({ intacctCompanyId }: UpdateWSUFormProps) {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const [inputErrors, setInputErrors] = useState<{
    password?: boolean;
    userId?: boolean;
  }>({});

  const handleSubmit: FormEventHandler<Element> = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!password || !userId) return;
    setLoading(true);
    setError(undefined);
    try {
      const data = {
        companyId: intacctCompanyId,
        password,
        userId,
      };
      await send({
        url: '/admin/update-wsu',
        method: 'POST',
        data,
        credentials: 'include',
        dontCheckForUnauthorized: true,
        useStream: true,
      });
      toastr.success('Credentials updated successfully');
    } catch (error_: any) {
      setError(error_.message);
    }
    setLoading(false);
  };

  const validateInput = (key: keyof typeof inputErrors, value: string) => {
    const _errs = { ...inputErrors };
    if (!value) {
      _errs[key] = true;
    } else {
      delete _errs[key];
    }
    setInputErrors(_errs);
  };

  const errorCount = Object.keys(inputErrors).length;

  return (
    <>
      <Form
        errorCount={errorCount}
        saveButton={
          <CardRow>
            <CardColumn>
              <FullWidthButton
                disabled={errorCount > 0}
                fullWidth
                buttonType="primary"
                key="verify"
                type="submit"
              >
                {loading ? (
                  <Loader isInsideButton />
                ) : (
                  <Translate scope="intacctAdmin.Save" />
                )}
              </FullWidthButton>
            </CardColumn>
          </CardRow>
        }
        onSubmit={handleSubmit}
      >
        <div className="inputs">
          <Textbox
            required
            error={inputErrors.userId ? 'This field is required' : undefined}
            label={I18njs.t('provision.WSUID')}
            labelInline={false}
            labelAlign="left"
            name="userId"
            onBlur={() => validateInput('userId', userId)}
            onChange={(e) => setUserId(e.target.value)}
            // @ts-expect-error -- type not on textbox
            type="text"
            value={userId}
          />
          <Textbox
            required
            error={inputErrors.password ? 'This field is required' : undefined}
            label={I18njs.t('provision.WSUPassword')}
            labelInline={false}
            labelAlign="left"
            name="password"
            onBlur={() => validateInput('password', password)}
            onChange={(e) => setPassword(e.target.value)}
            // @ts-expect-error -- type not on textbox
            type={showPassword ? 'text' : 'password'}
            value={password}
          >
            <Button
              buttonType="tertiary"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <Translate scope="auth.HIDE" />
              ) : (
                <Translate scope="auth.SHOW" />
              )}
            </Button>
          </Textbox>
        </div>
      </Form>
      {!!error && (
        <Box mt="10px">
          <Message variant="error">{error}</Message>
        </Box>
      )}
    </>
  );
}
