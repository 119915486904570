import Drawer from 'carbon-react/lib/components/drawer';
import { Tab, Tabs } from 'carbon-react/lib/components/tabs';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { config } from '../../config/env';
import { useInitializeLogoutHandler } from '../../helpers/auth';
import ErrorBoundary from '../ErrorBoundary';

const TAB_TO_PATH = {
  'tab-main': '/',
  'tab-companies': '/companies',
} as const;

const Sidebar = ({
  currentTab,
  setActive,
}: {
  currentTab: keyof typeof TAB_TO_PATH;
  setActive: (tabId: keyof typeof TAB_TO_PATH) => void;
}) => {
  return (
    <Tabs
      onTabChange={(id) => setActive(id as keyof typeof TAB_TO_PATH)}
      borders="no sides"
      selectedTabId={currentTab}
    >
      <Tab title="Main" tabId="tab-main" />
      <Tab title="Companies" tabId="tab-companies" />
      <Tab title="Queues" tabId="tab-queues" href={`${config.apiUrl}/queues`} />
    </Tabs>
  );
};

export function FullPageDrawer() {
  useInitializeLogoutHandler();
  const [activeTab, setActiveTab] =
    useState<keyof typeof TAB_TO_PATH>('tab-main');
  const navigate = useNavigate();
  const onTabChange = (tabId: keyof typeof TAB_TO_PATH) => {
    setActiveTab(tabId);
    navigate(TAB_TO_PATH[tabId]);
  };
  return (
    <ErrorBoundary>
      <div className="intacct-admin-container">
        <Drawer
          expandedWidth="200px"
          sidebar={<Sidebar currentTab={activeTab} setActive={onTabChange} />}
        >
          <Outlet />
        </Drawer>
      </div>
    </ErrorBoundary>
  );
}
