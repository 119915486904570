import Button from 'carbon-react/lib/components/button';
import Card, { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Dialog from 'carbon-react/lib/components/dialog';
import Form from 'carbon-react/lib/components/form';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import Textbox from 'carbon-react/lib/components/textbox';
import I18njs from 'i18n-js';
import { useState } from 'react';
import GoBackButton from '../../components/GoBackButton';
import Translate from '../../components/Translate/Translate';
import { send } from '../../helpers/requests';

const ResendInvite = () => {
  const [requestError, setRequestError] = useState(null);
  const [requestSuccess, setRequestSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(() => ({
    intacctCompanyId: '',
    username: '',
    email: '',
  }));
  const [visited, setVisited] = useState(() => ({
    intacctCompanyId: false,
    username: false,
    email: false,
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setVisited((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const errors = Object.keys(formData).filter(
    (key) => formData[key] === '' && visited[key] === true,
  ).length;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setRequestError(null);
      setRequestSuccess(false);
      setLoading(true);
      if (errors > 0) return;
      if (!formData.intacctCompanyId || !formData.username || !formData.email) {
        setVisited({
          intacctCompanyId: true,
          username: true,
          email: true,
        });
        return;
      }
      const data = { ...formData };
      const res = await send({
        method: 'POST',
        url: '/admin/invite-admin',
        data,
        credentials: 'include',
      });
      setRequestSuccess(res);
    } catch (error) {
      setRequestError(error);
      setRequestSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => setRequestSuccess(false);

  return (
    <div className="intacct-admin-inner-container">
      <GoBackButton />
      <Card>
        <CardRow>
          <CardColumn align="left">
            <Heading
              title={I18njs.t('intacctAdmin.ResendSITAdminInvitation')}
              mt={2}
            />
          </CardColumn>
        </CardRow>
        <Form
          errorCount={errors}
          onSubmit={handleSubmit}
          saveButton={
            <CardRow>
              <CardColumn>
                <Button buttonType="primary" type="submit">
                  {loading ? (
                    <Loader active isInsideButton />
                  ) : (
                    <Translate scope="intacctAdmin.ResendInvitation" />
                  )}
                </Button>
              </CardColumn>
            </CardRow>
          }
        >
          <Textbox
            error={
              formData.intacctCompanyId === '' && visited.intacctCompanyId
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.IntacctCompanyID')}
            name="intacctCompanyId"
            onChange={handleInputChange}
            required
            value={formData.intacctCompanyId}
          />
          <Textbox
            error={
              formData.username === '' && visited.username
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.Username')}
            name="username"
            onChange={handleInputChange}
            required
            value={formData.username}
          />
          <Textbox
            error={
              formData.email === '' && visited.email
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.UserEmail')}
            name="email"
            onChange={handleInputChange}
            required
            value={formData.email}
          />
          <Message variant="error" open={!!requestError}>
            {JSON.stringify(requestError?.message)}
          </Message>
        </Form>
      </Card>
      <Dialog
        onCancel={closeModal}
        title={I18njs.t('intacctAdmin.Success')}
        open={!!requestSuccess}
      >
        <div>
          <Translate
            scope="intacctAdmin.SuccessfulResendMessage"
            options={{ inviteLink: requestSuccess?.inviteLink }}
            markdown
          />
        </div>
        <div className="intacct-admin-modal-footer">
          <Button buttonType="primary" onClick={closeModal}>
            OK
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ResendInvite;
