import { Menu, MenuItem } from 'carbon-react/lib/components/menu';
import NavigationBar from 'carbon-react/lib/components/navigation-bar';
import { useNavigate } from 'react-router-dom';
import { useCompanyTreeSettingsIdInPathParams } from './helpers';

function RoutedMenuItem({
  href,
  onClick,
  children,
}: {
  href: `/companies/${string | number}`;
  onClick: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}) {
  return (
    <MenuItem href={href} onClick={onClick} flex="0 0 auto">
      {children}
    </MenuItem>
  );
}

export function CompanyNavigationBar() {
  const navigate = useNavigate();
  // This approach is better than `<Link><MenuItem /></Link>` because it doesn't
  // nest `<a>` tags, which is invalid HTML and causes issues in padding.
  const ctsId = useCompanyTreeSettingsIdInPathParams();
  const pathname = `/companies/${ctsId}` as const;

  return (
    <NavigationBar
      position="sticky"
      orientation="top"
      offset="25px"
      aria-label="header"
    >
      <Menu display="flex" flex="1">
        <RoutedMenuItem
          href={pathname}
          onClick={(e) => {
            e.preventDefault();
            navigate('');
          }}
        >
          Details
        </RoutedMenuItem>
        <RoutedMenuItem
          href={`${pathname}/entities`}
          onClick={(e) => {
            e.preventDefault();
            navigate('entities');
          }}
        >
          Entities
        </RoutedMenuItem>
        <RoutedMenuItem
          href={`${pathname}/triggers`}
          onClick={(e) => {
            e.preventDefault();
            navigate('triggers');
          }}
        >
          Triggers
        </RoutedMenuItem>
        <RoutedMenuItem
          href={`${pathname}/users`}
          onClick={(e) => {
            e.preventDefault();
            navigate('users');
          }}
        >
          Users
        </RoutedMenuItem>
      </Menu>
    </NavigationBar>
  );
}
