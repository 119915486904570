import I18njs from 'i18n-js';

import Markdown from '../Markdown/Markdown';

interface TranslateProps {
  scope: I18njs.Scope;
  markdown?: boolean;
  options?: I18njs.TranslateOptions;
}

const Translate = ({ scope, markdown, options }: TranslateProps) => {
  const translatedText = I18njs.t(scope, options);

  if (markdown) return <Markdown>{translatedText}</Markdown>;

  return <span>{translatedText}</span>;
};

export default Translate;
