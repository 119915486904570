import I18njs from 'i18n-js';
import { useController, useForm } from 'react-hook-form';

import Button from 'carbon-react/lib/components/button';
import { Card, CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Form from 'carbon-react/lib/components/form';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader';
import Textbox from 'carbon-react/lib/components/textbox';

import { useMutateUserPrimaryCompany } from '../../api/admin';
import GoBackButton from '../../components/GoBackButton';
import Translate from '../../components/Translate/Translate';
import toastr from '../../helpers/toastr';

interface UserCompanyForm {
  companyId: string;
  userId: string;
}

const MultiEntity = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<UserCompanyForm>();
  const { field: companyIdFields } = useController({
    name: 'companyId',
    control,
    rules: {
      required: true,
      minLength: 36,
      maxLength: 36,
      pattern: /^[\da-f-]+$/,
    },
    defaultValue: '',
  });
  const { field: userIdFields } = useController({
    name: 'userId',
    control,
    rules: {
      required: true,
      minLength: 36,
      maxLength: 36,
      pattern: /^[\da-f-]+$/,
    },
    defaultValue: '',
  });
  const mutation = useMutateUserPrimaryCompany();

  const onSubmit = async (data: UserCompanyForm) => {
    await mutation.mutateAsync(
      {
        companyId: data.companyId,
        userId: data.userId,
      },
      {
        onSuccess: () => {
          toastr.success('User company update successful');
          reset({
            companyId: '',
            userId: '',
          });
        },
        onError: (error) => {
          if (error instanceof Error) {
            toastr.error(error.message);
          } else {
            toastr.error(error as any);
          }
        },
      },
    );
  };

  return (
    <div className="intacct-admin-inner-container">
      <GoBackButton />
      <Card>
        <CardRow>
          <CardColumn align="left">
            <Heading title={I18njs.t('intacctAdmin.UpdateUserEntity')} mt={2} />
          </CardColumn>
        </CardRow>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          saveButton={
            <CardRow>
              <CardColumn>
                <Button buttonType="primary" type="submit">
                  {isSubmitting ? (
                    <Loader isActive isInsideButton />
                  ) : (
                    <Translate scope="intacctAdmin.Save" />
                  )}
                </Button>
              </CardColumn>
            </CardRow>
          }
        >
          <Textbox
            {...userIdFields}
            error={
              errors.userId?.type === 'required'
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : errors.userId?.message
            }
            label={I18njs.t('intacctAdmin.UserId')}
          />
          <Textbox
            {...companyIdFields}
            error={
              errors.companyId?.type === 'required'
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : errors.companyId?.message
            }
            label={I18njs.t('intacctAdmin.CompanyId')}
          />
        </Form>
      </Card>
    </div>
  );
};

export default MultiEntity;
