import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Form from 'carbon-react/lib/components/form';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import Textbox from 'carbon-react/lib/components/textbox';
import I18njs from 'i18n-js';
import { type FormEventHandler, useState } from 'react';
import GoBackButton from '../../components/GoBackButton';
import Translate from '../../components/Translate/Translate';
import { send } from '../../helpers/requests';
import MissingPermissions from '../MissingPermissions/MissingPermissions';

interface MissingPermission {
  module: string;
  activity: string;
  permissions: string[];
}

const ProvisionTest = () => {
  const [companyId, setCompanyId] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const [customIntacctEndpoint, setCustomIntacctEndpoint] = useState('');
  const [inputErrors, setInputErrors] = useState<Record<string, boolean>>({});
  const [missing, setMissing] = useState<MissingPermission[] | undefined>();

  const handleSubmit: FormEventHandler<Element> = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!password || !companyId || !userId) return;
    setLoading(true);
    setMissing(undefined);
    setError(undefined);
    try {
      const data = {
        companyId,
        password,
        userId,
      };
      const res: {
        missingPermissions: MissingPermission[];
      } = await send({
        url: '/admin/provision-test?keepAlive=true',
        method: 'POST',
        data,
        credentials: 'include',
        dontCheckForUnauthorized: true,
        useStream: true,
      });
      setMissing(res.missingPermissions);
    } catch (error_: unknown) {
      if (error_ instanceof Error) {
        setError(error_.message);
      } else if (typeof error_ === 'string') {
        setError(error_);
      } else {
        setError(I18njs.t('errors.messages.generic_error'));
      }
    }
    setLoading(false);
  };

  const validateInput = (key: string, value: string) => {
    const _errs = { ...inputErrors };
    if (!value) {
      _errs[key] = true;
    } else {
      delete _errs[key];
    }
    setInputErrors(_errs);
  };

  return (
    <div className="provision-container">
      <GoBackButton />
      <div className="provision-content">
        <div className="provision">
          <div className="card">
            <Form
              errorCount={Object.keys(inputErrors).length}
              saveButton={
                <Button
                  disabled={Object.keys(inputErrors).length > 0}
                  fullWidth
                  buttonType="primary"
                  key="verify"
                  type="submit"
                >
                  {loading ? (
                    <Loader isInsideButton />
                  ) : (
                    <Translate scope="provision.Verify" />
                  )}
                </Button>
              }
              onSubmit={handleSubmit}
            >
              <div className="title">
                <Translate scope="provision.EnterWSUCredentials" />
              </div>
              <div className="inputs">
                <Textbox
                  required
                  error={
                    inputErrors.companyId
                      ? I18njs.t('Thisfieldisrequired')
                      : undefined
                  }
                  label={I18njs.t('provision.CompanyID')}
                  labelInline={false}
                  labelAlign="left"
                  name="companyId"
                  onBlur={() => validateInput('companyId', companyId)}
                  onChange={(e) => setCompanyId(e.target.value)}
                  // @ts-expect-error -- carbon-react doesn't use type but it exists
                  type="text"
                  value={companyId}
                />
                <Textbox
                  required
                  error={
                    inputErrors.userId
                      ? I18njs.t('Thisfieldisrequired')
                      : undefined
                  }
                  label={I18njs.t('provision.WSUID')}
                  labelInline={false}
                  labelAlign="left"
                  name="userId"
                  onBlur={() => validateInput('userId', userId)}
                  onChange={(e) => setUserId(e.target.value)}
                  // @ts-expect-error -- carbon-react doesn't use type but it exists
                  type="text"
                  value={userId}
                />
                <Textbox
                  required
                  error={
                    inputErrors.password
                      ? I18njs.t('Thisfieldisrequired')
                      : undefined
                  }
                  label={I18njs.t('provision.WSUPassword')}
                  labelInline={false}
                  labelAlign="left"
                  name="password"
                  onBlur={() => validateInput('password', password)}
                  onChange={(e) => setPassword(e.target.value)}
                  // @ts-expect-error -- carbon-react doesn't use type but it exists
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                >
                  <Button
                    buttonType="tertiary"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? (
                      <Translate scope="auth.HIDE" />
                    ) : (
                      <Translate scope="auth.SHOW" />
                    )}
                  </Button>
                </Textbox>
                <Textbox
                  label={I18njs.t('provision.CustomIntacctEndpoint')}
                  labelInline={false}
                  labelAlign="left"
                  name="customIntacctEndpoint"
                  placeholder="https://api.intacct.com/ia/xml/xmlgw.phtml"
                  onChange={(e) => setCustomIntacctEndpoint(e.target.value)}
                  // @ts-expect-error typing broken for carbon-react, remove this once they fix it
                  type="text"
                  value={customIntacctEndpoint}
                />
              </div>
            </Form>
            {!!error && (
              <Box mt="10px">
                <Message variant="error">{error}</Message>
              </Box>
            )}
          </div>
          {!!missing && (
            <div className="card">
              {missing.length === 0 && (
                <div>The WSU has all required permissions</div>
              )}
              {missing.length > 0 && <MissingPermissions missing={missing} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProvisionTest;
