import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import { config } from '../../config/env';

const SamlLogin = () => {
  const location = useLocation();
  const initialUrl = (location.state as Record<string, string>)?.initialUrl;

  const storeInitialUrl = useCallback(() => {
    if (initialUrl) {
      sessionStorage.setItem('saml_initial_url', initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    storeInitialUrl();
    const cbUrl = `${window.location.origin}/saml/callback`;
    window.location.href = `${
      config.samlUrl
    }?returnUrl=${encodeURIComponent(cbUrl)}`;
  }, [storeInitialUrl]);

  return <Spinner />;
};

export default SamlLogin;
