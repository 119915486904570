import {
  useGetApiAdminUserById,
  useQueryUserFromCompanies,
} from '@sit/client-shared';

export function useGetApiUser(userId: string) {
  const user = useQueryUserFromCompanies(userId);

  return useGetApiAdminUserById(
    {
      pathParams: {
        id: userId,
      },
    },
    {
      // @ts-expect-error react-query v5 + OpenAPI does not support `initialData` yet: https://github.com/fabien0102/openapi-codegen/issues/184
      initialData: () => {
        if (user) {
          return { user };
        }
      },
    },
  );
}
