import { useGetAdminIsAuthenticated } from '@sit/client-shared';
import { Navigate, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';

const SamlProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isLoading, error } = useGetAdminIsAuthenticated();
  const { pathname } = useLocation();
  if (isLoading) return <Spinner />;

  if (error) return <Navigate to="/saml" state={{ initialUrl: pathname }} />;
  return children;
};

export default SamlProtectedRoute;
