import { Component, type ErrorInfo, type ReactNode } from 'react';
import * as React from 'react';

export interface ErrorBoundaryProps {
  children?: ReactNode;
  fallbackUI?:
    | ReactNode
    | ((error: Error, resetError: () => void) => ReactNode);
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

const INITIAL_ERROR_STATE = { hasError: false, error: null } as const;

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { ...INITIAL_ERROR_STATE };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any child components and re-render with error message
    if (import.meta.env.NODE_ENV === 'development') return;
    const nrWindow = window.newrelic;
    if (nrWindow && typeof nrWindow.noticeError === 'function')
      nrWindow.noticeError(error, { componentStack: errorInfo.componentStack });
  }

  resetError = (): void => {
    this.setState(INITIAL_ERROR_STATE);
  };

  override render(): ReactNode {
    const { children, fallbackUI } = this.props;
    const { hasError, error } = this.state;
    if (!hasError || !error) return children;
    if (React.isValidElement(fallbackUI)) {
      const FallbackUI = fallbackUI as unknown as React.ElementType;
      return <FallbackUI error={error} resetError={this.resetError} />;
    }
    if (typeof fallbackUI === 'function') {
      const fallbackComponent = fallbackUI(error, this.resetError);
      return React.isValidElement(fallbackComponent) ? fallbackComponent : null;
    }
    // If the consumer wants an empty UI, they can pass in `<></>` as the `fallbackUI`.
    return (
      <div style={{ padding: 40 }}>
        <h1>Something went wrong.</h1>
        <h3>Try refreshing the page.</h3>
        {import.meta.env.NODE_ENV !== 'production' && error.message && (
          <p>{error.message}</p>
        )}
        <h3>If the problem persists, please contact support.</h3>
      </div>
    );
  }
}
