import { usePostAdminSyncTriggersPackages } from '@sit/client-shared';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import styled, { css, keyframes } from 'styled-components';
import toastr from '../../helpers/toastr';

interface SyncTriggersPackageButtonProps {
  companyTreeSettingsId: number | string;
  externalId: string;
}
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinningIcon = styled(Icon)<{ isSyncingVersion: boolean }>`
  animation: ${(props) =>
    props.isSyncingVersion ? css`${spin} 2s linear infinite;` : 'none'};
`;

export function SyncTriggersPackageButton({
  companyTreeSettingsId,
  externalId,
}: SyncTriggersPackageButtonProps) {
  const { mutate: syncTriggersPackages, isLoading: isSyncingVersion } =
    usePostAdminSyncTriggersPackages();

  const onSync = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    syncTriggersPackages(
      {
        pathParams: {
          companyTreeSettingsId: `${companyTreeSettingsId}`,
        },
      },
      {
        onSuccess: (data) => {
          toastr.success(
            `Latest triggers package version for ${externalId} is ${
              data.triggerPackageVersion ?? 'not available'
            }`,
          );
        },
      },
    );
  };

  return (
    <IconButton
      py={0}
      mr={2}
      onClick={(e) => {
        onSync(e);
      }}
    >
      <SpinningIcon
        type="sync"
        isSyncingVersion={isSyncingVersion}
        tooltipMessage="Fetch the current triggers package version from Intacct"
      />
    </IconButton>
  );
}
