const en = {
  OK: 'OK',
  Back: 'Back',
  Loading: 'Loading',
  Cancel: 'Cancel',
  Clear: 'Clear',
  Delete: 'Delete',
  Generate: 'Generate',
  Search: 'Search',
  auth: {
    HIDE: 'Hide',
    SHOW: 'Show',
  },
  errors: {
    messages: {
      generic_error: 'Something went wrong. Please try again.',
      generic_request_error:
        "Oops! We can't connect to the server right now. Please wait a few minutes before refreshing the page.",
      unknown_error: 'Unrecognised error, please contact support.',
    },
  },
  network: {
    error: {
      offline: 'No internet connection',
    },
    info: {
      internetRestored: 'Internet connection restored',
    },
  },
  helmet: {
    Intacct: 'Intacct | Sage Intelligent Time',
    InternalAdminDashboard: 'Internal Admin Dashboard | Sage Intelligent Time',
    Provision: 'Provision | Sage Intelligent Time',
    SAML: 'SAML | Sage Intelligent Time',
  },
  intacct: {
    Approximatelyremaining: 'Approximately %{x} remaining...',
    IntacctConnectionInProgress: 'Intacct connection in progress',
    Retry: 'Retry',
    SHOW: 'Show',
    UserId: 'Web Services User ID',
    Werecurrentlyconnecting:
      "We're currently connecting to your Intacct instance. This usually takes no more than 5 minutes per Intacct entity. You can view the progress of the connection via the progress bar below. We will also email you when the connection is complete.",
  },
  intacctAdmin: {
    ALLOCATION_TEMPLATE: 'Allocation templates',
    APPROVAL: 'Approvals',
    CLASS: 'Classes',
    CONTRACT: 'Contracts',
    COSTTYPE: 'Cost types',
    CUSTOMER: 'Customers',
    DEPARTMENT: 'Departments',
    EMPLOYEEPOSITION: 'Employee positions',
    FIXEDASSET: 'Fixed assets',
    ITEM: 'Items',
    LABORCLASS: 'Labor classes',
    LABORSHIFT: 'Labor shifts',
    LABORUNION: 'Labor unions',
    LOCATION: 'Locations',
    PROJECT: 'Projects',
    PROJECTRESOURCE: 'Project resources',
    PROJECTSTATUS: 'Project statuses',
    TASK: 'Tasks',
    TASKRESOURCE: 'Task resources',
    TIMESHEET: 'Timesheets',
    TIMESHEETENTRY: 'Timesheet entries',
    TIMETYPE: 'Time types',
    VENDOR: 'Vendors',
    WAREHOUSE: 'Warehouses',
    AdminUsername: 'Admin username',
    AdminUserEmail: 'Admin user email',
    Back: 'Back',
    BackgroundJobs: 'Run background jobs',
    ChangeEntity: 'Change entity',
    CheckStatus: 'Check Status',
    ConfirmDelete:
      'Are you sure you want to delete **%{intacctCompanyId}**? This cannot be undone.',
    CompanyId: 'Company ID',
    Companyupdated: 'Company updated',
    CurrentEntity: 'Current Entity: **%{entity}**',
    Deactivate: 'Deactivate',
    Reactivate: 'Reactivate',
    DeactivateCompanyInstance: 'Deactivate company instance',
    ReactivateCompanyInstance: 'Reactivate company instance',
    DeleteCompanyInstance: 'Delete company instance',
    EmailAddress: 'Email address',
    GetCompanySyncStatus: 'Intacct sync status',
    GetEntities: 'Get entities',
    GrantSITAdminPermissions: 'Grant SIT admin permissions',
    IntacctCompanyID: 'Intacct company ID',
    IntacctSyncManual: 'Sync now',
    IntacctRefetchAllData: 'Refetch all data',
    IntacctSyncRunning: 'Last sync started %{syncStart}',
    IntacctSyncSuccess: 'Synced successfully %{syncEnd}',
    IntacctSyncProgress: 'Sync in progress %{progress}%',
    PasswordSet: 'Password set',
    ResendInvitation: 'Resend invitation',
    ResendSITAdminInvitation: 'Re-send SIT admin invitation',
    ReleaseHungSync: 'Release hung sync job',
    ResetMFA: 'Reset MFA',
    Revoke: 'Revoke',
    RevokeSITAdminPermissions: 'Revoke SIT admin permissions',
    RunNow: 'Run now',
    Save: 'Save',
    SelectAll: 'Select all',
    Submit: 'Submit',
    Step1RetrievecompanyEntities: 'Step 1: Retrieve company entities',
    Step2SelectenewEntity: 'Step 2: select new entity',
    Success: 'Success',
    SuccessfulDeactivationMessage:
      "You've successfully deactivated **%{companyID}**'s Sage Intelligent Time account.",
    SuccessfulReactivationMessage:
      "You've successfully reactivated **%{companyID}**'s Sage Intelligent Time account.",
    ScheduledDeleteMessage: '**%{companyID}** is scheduled for deletion.',
    SuccessfulDeleteMessage:
      "You've successfully deleted **%{companyID}**'s Sage Intelligent Time account.",
    SuccessfulEntityChangeMessage:
      "You've successfully updated **%{companyID}**'s Sage Intelligent Time company Entity.",
    SuccessfulGrantAdminMessage:
      "You've successfully granted Admin permissions to **%{username}**.",
    SuccessfulResendMessage:
      'Your invitation has been successfully sent. If the user still does not receive the invitation, please send them this link: %{inviteLink}',
    SuccessfulRevokeAdminMessage:
      "You've successfully revoked Admin permissions from **%{username}**.",
    Thisfieldisrequired: 'This field is required',
    UnsuccessfulDeleteMessage:
      'The **%{companyID}** company deletion was unsuccessful, retry in a few minutes.',
    UpdateCompanyDetails: 'Update company details',
    UpdateCompanyEntity: 'Update company entity',
    UpdateUserEntity: "Update user's primary Intacct entity",
    UserId: 'User ID',
    UserEmail: 'User email',
    Username: 'Username',
  },
  notFound: {
    GoBack: 'Go back',
    Sorrythispageisntavailable: "Sorry, this page isn't available.",
    Thelinkyoufollowed:
      'The link you followed may be broken, or the page may have been removed.',
  },
  provision: {
    CompanyID: 'Company ID',
    Confirm: 'Confirm',
    Continue: 'Continue',
    ContinueAnyway: 'Continue provisioning anyway?',
    CustomIntacctEndpoint: 'Custom Intacct endpoint URL',
    DeferEntitySelection: 'Defer implementation',
    Email: 'Email',
    EmailinIntacct: 'Email in Intacct',
    EnterWSUCredentials: 'Enter Web Services User credentials',
    GotoLogin: 'Go to Login',
    GotoHome: 'Go to Home',
    InviteAdmin: 'Invite admin',
    InviteandProvision: 'Invite and provision',
    InviteLink: 'Invite link',
    Name: 'Name',
    Pleaseselecttenanttype: 'Tenant type',
    ProvisionWithWarnings: 'Provision with warnings',
    ProvisionerPassword: 'Provisioner password',
    SelectEntity: 'Select entity',
    SelectIntacctUser: 'Select Intacct user',
    SelectTopLevel: 'Select "Top level"',
    StepCount: 'Step %{current} of %{total}',
    steps: {
      Step0: 'Enter credentials',
      Step1: 'Connect with Intacct',
      Step2: 'Invite admin',
    },
    TopLevelWarning: 'Warning: data from other entities will not be included',
    Username: 'Username',
    Verify: 'Verify',
    WSUID: 'Web Services User ID',
    WSUPassword: 'Web Services User password',
  },
};

export default en;
