import { Card, CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Heading from 'carbon-react/lib/components/heading';
import I18njs from 'i18n-js';
import GoBackButton from '../../components/GoBackButton';
import DeleteInstanceForm from './DeleteInstanceForm';

function DeleteInstance() {
  return (
    <div className="intacct-admin-inner-container">
      <GoBackButton />
      <Card>
        <CardRow>
          <CardColumn align="left">
            <Heading
              title={I18njs.t('intacctAdmin.DeleteCompanyInstance')}
              mt={2}
            />
          </CardColumn>
        </CardRow>
        <DeleteInstanceForm />
      </Card>
    </div>
  );
}

export default DeleteInstance;
