import type {
  CompanyProvisionDeferResponse,
  CompanyProvisionEntityResponse,
  PostApiAdminProvisionRequestBody,
  UserEntity,
} from '@sit/client-shared';
import type { TenantType } from '@sit/core';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import Form from 'carbon-react/lib/components/form';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import { Option, Select } from 'carbon-react/lib/components/select';
import Textbox from 'carbon-react/lib/components/textbox';
import Typography from 'carbon-react/lib/components/typography';
import I18njs from 'i18n-js';
import { type FormEvent, useState } from 'react';
import Translate from '../../components/Translate/Translate';
import { send } from '../../helpers/requests';
import MissingPermissions from '../MissingPermissions/MissingPermissions';
import { Sequence } from './Sequence';
import { Steps } from './steps';

interface MissingPermission {
  module: string;
  activity: string;
  permissions: string[];
}

export interface Step0WSUCredentialsProps {
  onSuccess: ({
    entities,
    companyId,
    companyTreeSettingsId,
  }: {
    entities: UserEntity[];
    companyId: string;
    companyTreeSettingsId: number;
  }) => void;
  deferEntitySelection: boolean;
  setDeferEntitySelection: (deferEntitySelection: boolean) => void;
}

function Step0WSUCredentials({
  deferEntitySelection,
  onSuccess,
  setDeferEntitySelection,
}: Step0WSUCredentialsProps) {
  const [companyId, setCompanyId] = useState('');
  const [tenantType, setTenantType] = useState<TenantType | undefined>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [customIntacctEndpoint, setCustomIntacctEndpoint] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const [inputErrors, setInputErrors] = useState<Record<string, boolean>>({});
  const [missing, setMissing] = useState<MissingPermission[] | undefined>();

  const fetchProvision = async () => {
    if (!tenantType) return;
    const data = {
      companyId,
      password,
      userId,
      deferEntitySelection,
      tenantType,
      customIntacctEndpoint: customIntacctEndpoint || null,
    } satisfies PostApiAdminProvisionRequestBody;
    const res: CompanyProvisionDeferResponse | CompanyProvisionEntityResponse =
      await send({
        url: '/admin/provision',
        method: 'POST',
        data,
        credentials: 'include',
        dontCheckForUnauthorized: true,
      });
    if (deferEntitySelection) {
      const deferRes = res as CompanyProvisionDeferResponse;
      onSuccess({
        entities: [],
        companyId,
        companyTreeSettingsId: deferRes.companyTreeSettingsId,
      });
    } else {
      const entityRes = res as CompanyProvisionEntityResponse;
      const _ent = [...entityRes.entities];
      if (!_ent) {
        onSuccess({
          entities: [],
          companyId,
          companyTreeSettingsId: entityRes.companyTreeSettingsId,
        });
      } else {
        onSuccess({
          entities: _ent,
          companyId,
          companyTreeSettingsId: entityRes.companyTreeSettingsId,
        });
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;
    if (!password || !companyId || !userId || !tenantType) return;
    setLoading(true);
    setError(undefined);
    setMissing(undefined);
    try {
      const check = deferEntitySelection
        ? null
        : await send({
            url: '/admin/provision-test?keepAlive=true',
            method: 'POST',
            data: {
              companyId,
              userId,
              password,
              customIntacctEndpoint: customIntacctEndpoint || null,
            },
            credentials: 'include',
            dontCheckForUnauthorized: true,
            useStream: true,
          });
      if (check?.missingPermissions.length) {
        setMissing(check.missingPermissions);
      } else {
        await fetchProvision();
      }
    } catch (error_: any) {
      setError(error_.message);
    }
    setLoading(false);
  };

  const validateInput = (key: string, value: string) => {
    const _errs: Record<string, boolean> = { ...inputErrors };
    if (!value) {
      _errs[key] = true;
    } else {
      delete _errs[key];
    }
    setInputErrors(_errs);
  };

  const errorCount = Object.keys(inputErrors).length;

  return (
    <div className="provision">
      <div className="card">
        <Form
          errorCount={errorCount}
          saveButton={
            <Button
              disabled={errorCount > 0}
              fullWidth
              buttonType="primary"
              key="verify"
              type="submit"
            >
              {loading ? (
                <Loader isInsideButton />
              ) : (
                <Translate scope="provision.Verify" />
              )}
            </Button>
          }
          onSubmit={handleSubmit}
        >
          <Box pb={4}>
            <Sequence step={Steps.WSU_CREDENTIALS} />
          </Box>
          <div className="inputs">
            <Textbox
              required
              error={
                inputErrors.companyId ? 'This field is required' : undefined
              }
              label={I18njs.t('provision.CompanyID')}
              labelInline={false}
              labelAlign="left"
              name="companyId"
              onBlur={() => validateInput('companyId', companyId)}
              onChange={(e) => setCompanyId(e.target.value)}
              // @ts-expect-error typing broken for carbon-react, remove this once they fix it
              type="text"
              value={companyId}
            />
            <Textbox
              required
              error={inputErrors.userId ? 'This field is required' : undefined}
              label={I18njs.t('provision.WSUID')}
              labelInline={false}
              labelAlign="left"
              name="userId"
              onBlur={() => validateInput('userId', userId)}
              onChange={(e) => setUserId(e.target.value)}
              // @ts-expect-error typing broken for carbon-react, remove this once they fix it
              type="text"
              value={userId}
            />
            <Textbox
              required
              error={
                inputErrors.password ? 'This field is required' : undefined
              }
              label={I18njs.t('provision.WSUPassword')}
              labelInline={false}
              labelAlign="left"
              name="password"
              onBlur={() => validateInput('password', password)}
              onChange={(e) => setPassword(e.target.value)}
              // @ts-expect-error typing broken for carbon-react, remove this once they fix it
              type={showPassword ? 'text' : 'password'}
              value={password}
            >
              <Button
                buttonType="tertiary"
                onClick={() => setShowPassword((s) => !s)}
              >
                {showPassword ? (
                  <Translate scope="auth.HIDE" />
                ) : (
                  <Translate scope="auth.SHOW" />
                )}
              </Button>
            </Textbox>
            <Select
              label={I18njs.t('provision.Pleaseselecttenanttype')}
              required
              onChange={(e) => setTenantType(e.target.value as TenantType)}
              value={`${tenantType}`}
            >
              <Option value="customer_production" text="Customer Production" />
              <Option value="partner_production" text="Partner Production" />
              <Option value="SIAP_production" text="SIAP Production" />
              <Option value="MEDC_production" text="MEDC Production" />
              <Option value="sandbox" text="Sandbox" />
              <Option value="developer" text="Developer" />
              <Option value="sales_demo" text="Sales Demo" />
              <Option value="implementation" text="Implementation" />
              <Option value="debug" text="Debug" />
            </Select>
            <Textbox
              label={I18njs.t('provision.CustomIntacctEndpoint')}
              labelInline={false}
              labelAlign="left"
              name="customIntacctEndpoint"
              placeholder="https://api.intacct.com/ia/xml/xmlgw.phtml"
              onChange={(e) => setCustomIntacctEndpoint(e.target.value)}
              // @ts-expect-error typing broken for carbon-react, remove this once they fix it
              type="text"
              value={customIntacctEndpoint}
            />
            <Checkbox
              id="deferEntitySelection"
              onChange={({ target }) => setDeferEntitySelection(target.checked)}
              checked={deferEntitySelection}
              label={I18njs.t('provision.DeferEntitySelection')}
            />
          </div>
        </Form>
        {missing && missing.length > 0 && (
          <>
            <MissingPermissions missing={missing} />
            <Box mt={3}>
              <Typography variant="strong">
                {I18njs.t('provision.ContinueAnyway')}
              </Typography>
              <Box mt={1}>
                <Button
                  buttonType="secondary"
                  destructive
                  onClick={fetchProvision}
                >
                  <Translate scope="provision.ProvisionWithWarnings" />
                </Button>
              </Box>
            </Box>
          </>
        )}
        {error && (
          <Box mt="10px">
            <Message variant="error">{error}</Message>
          </Box>
        )}
      </div>
    </div>
  );
}

export default Step0WSUCredentials;
