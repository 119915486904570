import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import AppProviders from '../../AppProviders';
import { FullPageDrawer } from '../../components/Drawer/Drawer';
import SamlProtectedRoute from '../../components/SamlProtectedRoute';
import ChangeAdminPermissions from '../ChangeAdminPermissions/ChangeAdminPermissions';
import { CompaniesPage } from '../Companies/CompaniesPage';
import { CompanyPage } from '../Company/Company';
import { CompanyDetailsPage } from '../Company/CompanyDetails/CompanyDetailsPage';
import { CompanyEntitiesPage } from '../Company/CompanyEntities/CompanyEntitiesPage';
import { CompanyTriggersPage } from '../Company/CompanyTriggers/CompanyTriggersPage';
import { CompanyUsersPage } from '../Company/CompanyUsers/CompanyUsersPage';
import DeleteInstance from '../DeleteInstance/DeleteInstance';
import IntacctAdmin from '../Home';
import MultiEntity from '../MultiEntity';
import NotFoundPage from '../NotFoundPage';
import Provision from '../Provision';
import ProvisionTest from '../ProvisionTest/ProvisionTest';
import QueuesPage from '../Queues';
import ResendInvite from '../ResendInvite/ResendInvite';
import ResetMfa from '../ResetMfa/ResetMfa';
import RunAnyJobForm from '../RunJobs/RunAnyJob';
import Saml from '../Saml';
import SamlCallback from '../Saml/SamlCallback';
import SamlLogin from '../Saml/SamlLogin';
import { UserPage } from '../User/UserPage';

const Root = () => {
  return (
    <AppProviders>
      <FullPageDrawer />
    </AppProviders>
  );
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: '/saml',
        element: <Saml />,
        children: [
          {
            path: '',
            element: <SamlLogin />,
          },
          {
            path: 'callback',
            element: <SamlCallback />,
          },
          {
            path: '*',
            element: <Navigate to="/" />,
          },
        ],
      },
      {
        path: '/',
        index: true,
        element: (
          <SamlProtectedRoute>
            <IntacctAdmin />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/companies',
        element: (
          <SamlProtectedRoute>
            <CompaniesPage />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/companies/:id',
        element: (
          <SamlProtectedRoute>
            <CompanyPage />
          </SamlProtectedRoute>
        ),
        children: [
          {
            path: '',
            index: true,
            element: <CompanyDetailsPage />,
          },
          {
            path: 'entities',
            element: <CompanyEntitiesPage />,
          },
          {
            path: 'triggers',
            element: <CompanyTriggersPage />,
          },
          {
            path: 'users',
            element: <CompanyUsersPage />,
          },
        ],
      },
      {
        path: '/delete-instance',
        element: (
          <SamlProtectedRoute>
            <DeleteInstance />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/provision',
        element: (
          <SamlProtectedRoute>
            <Provision />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/provision-test',
        element: (
          <SamlProtectedRoute>
            <ProvisionTest />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/resend-invite',
        element: (
          <SamlProtectedRoute>
            <ResendInvite />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/reset-mfa',
        element: (
          <SamlProtectedRoute>
            <ResetMfa />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/multi-entity',
        element: (
          <SamlProtectedRoute>
            <MultiEntity />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/grant-admin-permissions',
        element: (
          <SamlProtectedRoute>
            <ChangeAdminPermissions makeAdmin />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/queues',
        element: (
          <SamlProtectedRoute>
            <QueuesPage />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/revoke-admin-permissions',
        element: (
          <SamlProtectedRoute>
            <ChangeAdminPermissions />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/run-jobs',
        element: (
          <SamlProtectedRoute>
            <RunAnyJobForm />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '/users/:id',
        element: (
          <SamlProtectedRoute>
            <UserPage />
          </SamlProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
