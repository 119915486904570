import Button from 'carbon-react/lib/components/button';
import Card, { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Form from 'carbon-react/lib/components/form';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import Textbox from 'carbon-react/lib/components/textbox';
import I18njs from 'i18n-js';
import { useState } from 'react';
import GoBackButton from '../../components/GoBackButton';
import Translate from '../../components/Translate/Translate';
import { send } from '../../helpers/requests';
import toastr from '../../helpers/toastr';

const ResetMfa = () => {
  const [requestError, setRequestError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(() => ({
    intacctCompanyId: '',
    username: '',
    email: '',
  }));
  const [visited, setVisited] = useState(() => ({
    intacctCompanyId: false,
    username: false,
    email: false,
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setVisited((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const errors = Object.keys(formData).filter(
    (key) => formData[key] === '' && visited[key] === true,
  ).length;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setRequestError(null);
      setLoading(true);
      if (errors > 0) return;
      if (!formData.intacctCompanyId || !formData.username || !formData.email) {
        setVisited({
          intacctCompanyId: true,
          username: true,
          email: true,
        });
        return;
      }
      const data = { ...formData };
      await send({
        method: 'POST',
        url: '/admin/reset-mfa',
        data,
        credentials: 'include',
      });
      toastr.success('MFA reset successfully');
    } catch (error) {
      setRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="intacct-admin-inner-container">
      <GoBackButton />
      <Card>
        <CardRow>
          <CardColumn align="left">
            <Heading title={I18njs.t('intacctAdmin.ResetMFA')} mt={2} />
          </CardColumn>
        </CardRow>
        <Form
          errorCount={errors}
          onSubmit={handleSubmit}
          saveButton={
            <CardRow>
              <CardColumn>
                <Button buttonType="primary" type="submit">
                  {loading ? (
                    <Loader active isInsideButton />
                  ) : (
                    <Translate scope="intacctAdmin.ResetMFA" />
                  )}
                </Button>
              </CardColumn>
            </CardRow>
          }
        >
          <Textbox
            error={
              formData.intacctCompanyId === '' && visited.intacctCompanyId
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.IntacctCompanyID')}
            name="intacctCompanyId"
            onChange={handleInputChange}
            required
            value={formData.intacctCompanyId}
          />
          <Textbox
            error={
              formData.username === '' && visited.username
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.Username')}
            name="username"
            onChange={handleInputChange}
            required
            value={formData.username}
          />
          <Textbox
            error={
              formData.email === '' && visited.email
                ? I18njs.t('intacctAdmin.Thisfieldisrequired')
                : ''
            }
            label={I18njs.t('intacctAdmin.UserEmail')}
            name="email"
            onChange={handleInputChange}
            required
            value={formData.email}
          />
          <Message variant="error" open={!!requestError}>
            {JSON.stringify(requestError?.message)}
          </Message>
        </Form>
      </Card>
    </div>
  );
};

export default ResetMfa;
