export const Steps = {
  WSU_CREDENTIALS: Symbol('WSU_CREDENTIALS'),
  SYNC: Symbol('SYNC'),
  LAST_STEP: Symbol('LAST_STEP'),
};

export const useStepNumber = (step: (typeof Steps)[keyof typeof Steps]) => {
  switch (step) {
    case Steps.WSU_CREDENTIALS:
      return { stepNumber: 0, name: 'Provide WSU credentials' } as const;
    case Steps.SYNC:
      return { stepNumber: 1, name: 'Sync' } as const;
    case Steps.LAST_STEP:
      return {
        stepNumber: 2,
        name: 'Company successfuly provisioned',
      } as const;
    default:
      throw new Error('Invalid step');
  }
};
