import { useGetCompanyById } from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box';
import Heading from 'carbon-react/lib/components/heading';
import { Outlet } from 'react-router-dom';
import GoBackButton from '../../components/GoBackButton';
import { CompanyNavigationBar } from './CompanyNavigationBar';
import { useCompanyTreeSettingsIdInPathParams } from './helpers';

export function CompanyPage() {
  const companyTreeSettingsId = useCompanyTreeSettingsIdInPathParams();
  const { data: company, isLoading } = useGetCompanyById({
    companyTreeSettingsId,
  });

  const companyLoading = isLoading || !company;

  return (
    <div className="intacct-admin">
      <Box display="flex">
        <GoBackButton url="/companies" width="auto" />
        <Heading
          title={`Company - ${
            companyLoading ? 'Loading...' : company.externalId
          }`}
          divider={false}
        />
      </Box>
      <div id="sticky-container" style={{ height: '100px' }}>
        <CompanyNavigationBar />
        <div className="intacct-admin-inner-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
