import {
  invalidateAdminCompanyTreeQueryData,
  useGetApiAdminJobStatus,
  usePostAdminTriggersPackages,
} from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import FileInput from 'carbon-react/lib/components/file-input';
import Form from 'carbon-react/lib/components/form';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import { useCallback, useEffect, useState } from 'react';
import { type UseFormSetError, useController, useForm } from 'react-hook-form';
import toastr from '../../../helpers/toastr';

interface CompanyTriggersPackageForm {
  triggersXMLData: string;
}

interface CompanyTriggersFormProps {
  companyTreeSettingsIds: number[];
}

function useGetAdminJobStatus(
  jobId: string | undefined,
  setError: UseFormSetError<CompanyTriggersPackageForm>,
) {
  return useGetApiAdminJobStatus(
    {
      queryParams: {
        // biome-ignore lint/style/noNonNullAssertion: enabled
        jobId: jobId!,
        queueName: 'update-triggers-packages',
      },
      requestConfig: {
        skipToast: true,
      },
    },
    {
      enabled: jobId != null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 2_000,
      retry: (failureCount, error) => {
        if (error?.status === 404) {
          return false;
        }
        if (!(error instanceof Error)) {
          return false;
        }
        if (error.message?.includes('Too many requests') || failureCount < 5) {
          return true;
        }
        setError('root', new Error(error.message));
        return false;
      },
    },
  );
}

export function CompanyTriggersForm({
  companyTreeSettingsIds,
}: CompanyTriggersFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<CompanyTriggersPackageForm>();
  const queryClient = useQueryClient();
  const [jobId, setJobId] = useState<string | undefined>();
  const { error: pollError } = useGetAdminJobStatus(jobId, setError);
  const [file, setFile] = useState<File | undefined>();
  const { mutate: updateTriggersPackages } = usePostAdminTriggersPackages();
  const { field: triggersXmlDataField } = useController({
    name: 'triggersXMLData',
    control,
    defaultValue: '',
  });

  const onSuccess = useCallback(() => {
    toastr.success('Update company triggers package job complete');
    setJobId(undefined);
    void invalidateAdminCompanyTreeQueryData(queryClient);
  }, [queryClient]);

  useEffect(() => {
    if (pollError?.status === 404) {
      onSuccess();
    }
  }, [pollError?.status, onSuccess]);

  const onSubmit = () => {
    if (!file) {
      setError('triggersXMLData', new Error('Please select a file'));
      return;
    }
    updateTriggersPackages(
      {
        body: {
          companyTreeSettingsIds,
          fileName: file,
        },
      },
      {
        onSuccess: (data) => {
          reset();
          setJobId(data.jobId);
          toastr.info('Triggers package update started.');
        },
        onError: (error) => {
          setError('triggersXMLData', error);
        },
      },
    );
  };

  function onSelectFiles(files: FileList) {
    setFile(files[0]);
  }

  return (
    <Form
      errorCount={Object.values(errors).length}
      onSubmit={handleSubmit(onSubmit)}
      saveButton={
        <Button buttonType="primary" type="submit">
          {isSubmitting || jobId != null ? <Loader isInsideButton /> : 'Save'}
        </Button>
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Heading
          headingType="h2"
          title="Update triggers package"
          divider={false}
        />
        <Message variant="warning">
          Make sure the version is higher than the current version.
        </Message>
        <FileInput
          minWidth="100%"
          minHeight="150px"
          mb={-2}
          label="XML trigger package file input"
          required
          buttonText={file ? file.name : 'Select file'}
          dragAndDropText={
            file ? 'File selected' : 'or drag and drop your file'
          }
          name={triggersXmlDataField.name}
          onChange={onSelectFiles}
          accept="text/xml"
        />
      </Box>
    </Form>
  );
}
