import Dialog from 'carbon-react/lib/components/dialog';
import { CompanyTriggersForm } from '../../Company/CompanyTriggers/CompanyTriggersForm';

interface UploadTriggersPackageModalProps {
  open: boolean;
  onCancel?: (
    ev:
      | React.KeyboardEvent<HTMLElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  companyTreeSettingsIds: number[];
}

export function UploadTriggersPackageModal({
  open,
  onCancel,
  companyTreeSettingsIds,
}: UploadTriggersPackageModalProps) {
  return (
    <Dialog open={open} onCancel={onCancel}>
      <CompanyTriggersForm companyTreeSettingsIds={companyTreeSettingsIds} />
    </Dialog>
  );
}
