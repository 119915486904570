import 'core-js/stable';
import 'react-toastify/dist/ReactToastify.min.css';
import './helpers/initialize-client-shared';
import './styles/index.scss';

import { initializeAxiosRequestInterceptor } from '@sit/client-shared';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { SetupWorker } from 'msw/browser';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './containers/App';
import { queryClient } from './query';

const LOADING_SCREEN_SELECTOR = 'assets-loading';
const HIDE_CLASS = 'hide';

function hideLoadingScreen() {
  const loadingScreen = document.getElementsByClassName(
    LOADING_SCREEN_SELECTOR,
  )[0];
  loadingScreen?.classList.add(HIDE_CLASS);
}

if (
  import.meta.env.NODE_ENV === 'development' &&
  !import.meta.env.VITE_APP_MSW_DISABLED
) {
  // import("./__mocks__/browser").then(({ worker }) => {
  //   worker.start();
  // });
  // ^ isn't as accurate because we need to load msw synchronously
  // to catch the first page request (404 otherwise).
  // There is no significant performance difference between import and require.

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const worker = require('./__mocks__/browser').worker as SetupWorker;

  worker.start({
    onUnhandledRequest: 'bypass',
    quiet: import.meta.env.VITE_APP_LOG_MSW !== 'true',
  });
}

hideLoadingScreen();
initializeAxiosRequestInterceptor();

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root'),
);

if (import.meta.env.NODE_ENV === 'development') {
  import('./reportWebVitals')
    .then(({ reportWebVitals }) => reportWebVitals(console.log))
    .catch((error) => {
      console.error('Failed to load reportWebVitals', error);
    });
}
