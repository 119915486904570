import {
  type GetApiAdminCompanyByCompanyTreeSettingsIdResponse,
  useGetCompanyById,
} from '@sit/client-shared';
import { useCompanyTreeSettingsIdInPathParams } from '../helpers';
import { CompanyEntitiesTable } from './CompanyEntitiesTable';

export interface CompanyEntitiesPageProps {
  company: GetApiAdminCompanyByCompanyTreeSettingsIdResponse;
}

export function CompanyEntitiesPage() {
  const companyTreeSettingsId = useCompanyTreeSettingsIdInPathParams();
  const { data: company, isLoading } = useGetCompanyById({
    companyTreeSettingsId,
  });

  if (isLoading || !company) {
    return <div>Loading...</div>;
  }

  return <CompanyEntitiesTable entities={company.entities} />;
}
