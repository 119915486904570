import type { PropsWithChildren } from 'react';
import { type AdminFeatureFlagNames, useFlag } from '../../launch-darkly/flags';

interface FeatureFlagProps<T extends AdminFeatureFlagNames> {
  featureName: T;
}

/**
 *  This component is used for `BOOLEAN` feature flags.
 *  If `TRUE` it renders children, if `FALSE` it renders `null`
 */
export const FeatureFlag = <T extends AdminFeatureFlagNames>({
  children,
  featureName,
}: PropsWithChildren<FeatureFlagProps<T>>) => {
  const flag = useFlag(featureName);
  return flag ? <>{children}</> : null;
};
