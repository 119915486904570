import { LDProvider } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';
import { v4 } from 'uuid';
import buildLaunchDarklyConfig from './launchDarklyConfig';

// TODO: Remove this once we have a proper way to get the user id (from SAML)
const key = v4();

export function LaunchDarklyProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const clientSideID = import.meta.env.VITE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;
  if (!clientSideID) {
    return <>{children}</>;
  }
  const disabled = import.meta.env.VITE_APP_LAUNCH_DARKLY_DISABLED === 'true';
  return (
    <LDProvider
      {...buildLaunchDarklyConfig({ clientSideID, disabled })}
      user={{
        key,
      }}
    >
      {children}
    </LDProvider>
  );
}
