import { config } from '../config/env';

const baseQueryLink = 'https://service.sumologic.com/ui/#/search/create?query=';

const env = config.env === 'preprod' ? 'preprod' : 'prod';

export const webHttpErrors = `
_sourceCategory=lsm/na/${env}/sit* and _sourceHost=na/${env}/sit/api/web/application_log and not "SIT_REST_API" and not "Invalid Web Services Authorization The sender ID" and not "Sign-in information is incorrect" and not "The company you have attempted to login into is no longer active" and not "You do not have permission for API operation" and not "Error when trying to retrieve events: Bad Request" and not "renewAccessToken" and not "invalid_grant" and not "info: [LaunchDarkly]" and not "request to https://api.intacct.com/ia/xml/xmlgw.phtml failed, reason: read ECONNRESET" and not "FetchError: network timeout" and not "FetchError: Response timeout while trying to fetch" and not "Client network socket disconnected before secure TLS connection was established" and not "Request retry count exceeded max retry count" and not "The Service is Temporarily Off-line" and not "Intacct service is down for upgrades" and not "MaxListenersExceededWarning" and not "String based operators are deprecated" and not "Importing JSON modules is an experimental feature" and not "Cannot send data change message" and not "DATA_CHANGE_MESSAGE_QUEUE_PREFIX" and not "This user is in LOCKEDOUT state" and not "XL03000012 A payment is due for the company id" and not "Login information is incorrect" and not "Error on refreshing token"
| json auto "message"
| message.payload.requestId as requestId
| message.payload.companyTreeSettingsId as companyTreeSettingsId
| message.payload.tenantType as tenantType
| message.payload.err.message as errorMessage
| message.payload.err.stack as stack
| message.msg as msg
| message.level as level
| fields msg, errorMessage, stack, companyTreeSettingsId, tenantType, requestId, level
`;

const allLogsForCompany = (externalId: string) => `
_sourceCategory=lsm/na/${env}/sit* and (_sourceHost=na/${env}/sit/api/web/application_log or _sourceHost=na/${env}/sit/api/worker/application_log) and "${externalId}" and not "sync-status" | json auto "message"
`;

function createSumoLogicQueryLink(
  query: string,
): `${typeof baseQueryLink}${string}` {
  return `${baseQueryLink}${encodeURIComponent(query)}&startTime=-12h`;
}

export function createSumoLogicQueryLinkForCompany(externalId: string) {
  return createSumoLogicQueryLink(allLogsForCompany(externalId));
}
