import CarbonProvider from 'carbon-react/lib/components/carbon-provider';
import I18nProvider from 'carbon-react/lib/components/i18n-provider';
import GlobalStyle from 'carbon-react/lib/style/global-style';
import Aegean from 'carbon-react/lib/style/themes/sage';
import I18njs from 'i18n-js';
import type { PropsWithChildren } from 'react';
import { LaunchDarklyProvider } from './analytics/LaunchDarklyProvider';
import ToastContainer from './components/ToastContainer';
import locales from './locales';

I18njs.translations.en = locales.en;
I18njs.defaultLocale = 'en';

type AppProviderProps = Record<string, unknown>;

export default function AppProviders({
  children,
}: PropsWithChildren<AppProviderProps>) {
  return (
    // I18nProvider is not typed properly
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <I18nProvider locale={locales.en}>
      <LaunchDarklyProvider>
        <CarbonProvider theme={Aegean}>
          <GlobalStyle />
          {children}
          <ToastContainer />
        </CarbonProvider>
      </LaunchDarklyProvider>
    </I18nProvider>
  );
}
