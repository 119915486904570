import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Heading from 'carbon-react/lib/components/heading';
import Pod from 'carbon-react/lib/components/pod';
import { useSearchParams } from 'react-router-dom';
import { UploadTriggersPackageModal } from './BulkActionModals/UploadTriggersPackageModal';

interface BulkActionsProps {
  companyTreeSettingsIds: number[];
}

type BulkActionsModalType = 'uploadTriggersPackage' | 'deactivate' | 'delete';

export function BulkActions({ companyTreeSettingsIds }: BulkActionsProps) {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const disabled = companyTreeSettingsIds.length === 0;

  const createOnOpen = (modal: BulkActionsModalType) => () => {
    setUrlSearchParams({ modal });
  };

  const clearModal = () => {
    setUrlSearchParams();
  };

  return (
    <>
      <Box pt={2}>
        <Pod>
          <Heading headingType="h3" title="Bulk actions" />
          <Box display="flex" flexDirection="row" gap={2}>
            <Button
              disabled={disabled}
              buttonType="secondary"
              onClick={createOnOpen('uploadTriggersPackage')}
            >
              Upload trigger package
            </Button>
          </Box>
        </Pod>
      </Box>
      <UploadTriggersPackageModal
        open={urlSearchParams.get('modal') === 'uploadTriggersPackage'}
        onCancel={clearModal}
        companyTreeSettingsIds={companyTreeSettingsIds}
      />
    </>
  );
}
