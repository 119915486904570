import Button from 'carbon-react/lib/components/button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Translate from '../Translate/Translate';

const Container = styled.div<{ width?: string }>`
  padding: 5px;
  width: ${(props) => props.width || '100%'};
`;

const GoBackButton = ({
  url,
  width,
}: { url?: string | undefined; width?: string }) => {
  const navigate = useNavigate();
  const goBack = () => (url != null ? navigate(url) : navigate(-1));
  return (
    <Container width={width}>
      <Button buttonType="tertiary" onClick={goBack} size="small">
        <Translate scope="Back" />
      </Button>
    </Container>
  );
};

export default GoBackButton;
