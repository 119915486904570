import { getProgress } from '@sit/client-shared';
import { useState } from 'react';
import { useQuerySyncStatus } from '../../api/admin';

export function useSyncStatus(
  companyTreeSettingsId: number | undefined,
  onError: (error: string | null) => void,
) {
  const [attempts, setAttempts] = useState(0);
  const [previousProgress, setPreviousProgress] = useState(0);
  const { data } = useQuerySyncStatus(companyTreeSettingsId!, false, {
    onSuccess: (data) => {
      const newProgress = getProgress(data);
      if (newProgress === 100) {
        return;
      }
      if (attempts >= 300) {
        onError('Job timed out');
        return false;
      }
      if (previousProgress === newProgress) {
        setAttempts((prevAttempts) => prevAttempts + 1);
      } else {
        setAttempts(0);
      }
      if (newProgress) setPreviousProgress(newProgress);
    },
    refetchInterval: () => {
      return 2500;
    },
    retry: (failureCount, error: unknown) => {
      const e = error as Error;
      onError(e.message);
      return false;
    },
    enabled: companyTreeSettingsId != null,
  });
  return {
    progress: getProgress(data) || 0,
    data,
  };
}
