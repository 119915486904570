import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import { useNavigate } from 'react-router-dom';
import Translate from '../../components/Translate/Translate';
import { Sequence } from './Sequence';
import { Steps } from './steps';

const LastStep = () => {
  const navigate = useNavigate();
  return (
    <div className="provision">
      <div className="card">
        <Box pb={4}>
          <Sequence step={Steps.LAST_STEP} />
        </Box>
        <Button buttonType="primary" fullWidth onClick={() => navigate('/')}>
          <Translate scope="provision.GotoHome" />
        </Button>
      </div>
    </div>
  );
};

export default LastStep;
