import {
  type GetApiAdminCompaniesResponse,
  type GetApiAdminIntacctCompanyInfoResponse,
  getProgress,
  queryKeyFn,
  useDeleteApiAdminCompanyTreeSettings,
  useGetApiAdminJobStatus,
} from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Dialog from 'carbon-react/lib/components/dialog';
import Form from 'carbon-react/lib/components/form';
import Hr from 'carbon-react/lib/components/hr/hr.component';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import Textbox from 'carbon-react/lib/components/textbox';
import Text from 'carbon-react/lib/components/typography';
import I18njs from 'i18n-js';
import { useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useController, useForm } from 'react-hook-form';
import { useQueryIntacctCompanyInfo } from '../../api/admin';
import { ProgressBar } from '../../components/ProgressBar';
import Translate from '../../components/Translate/Translate';
import toastr from '../../helpers/toastr';

interface DeleteCompanyForm {
  intacctCompanyId: string;
}

function DeleteInstanceForm() {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<DeleteCompanyForm>();
  const { field: intacctCompanyIdFields } = useController({
    name: 'intacctCompanyId',
    control,
    rules: {
      required: {
        value: true,
        message: I18njs.t('intacctAdmin.Thisfieldisrequired'),
      },
    },
    defaultValue: '',
  });
  const intacctCompanyId = watch('intacctCompanyId');
  const [companyData, setCompanyData] = useState<
    GetApiAdminIntacctCompanyInfoResponse | undefined
  >();

  const [confirmationState, setConfirmationState] = useState<
    'waiting' | 'fetching' | 'fetched' | 'confirming'
  >('waiting');
  const [jobId, setJobId] = useState<string | undefined>();
  const [error, setError] = useState<any>();
  const [intervalMs, setIntervalMs] = useState<number | false>(2500);

  const mutation = useDeleteApiAdminCompanyTreeSettings();

  useQueryIntacctCompanyInfo(intacctCompanyId, {
    enabled: confirmationState === 'fetching' && intacctCompanyId !== '',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setConfirmationState('fetched');
      setCompanyData(data);
    },
    onError: (error) => {
      toastr.error(error instanceof Error ? error.message : (error as string));
      setConfirmationState('waiting');
    },
  });

  const resetForm = useCallback(() => {
    setConfirmationState('waiting');
    setCompanyData(undefined);
    reset({
      intacctCompanyId: '',
    });
  }, [reset]);

  const onSuccess = useCallback(() => {
    resetForm();
    toastr.success(
      I18njs.t('intacctAdmin.SuccessfulDeleteMessage', {
        companyID: intacctCompanyId,
      }),
    );
    setJobId(undefined);
    setIntervalMs(false);
    queryClient.setQueryData(
      queryKeyFn({
        path: '/admin/companies',
        operationId: 'getApiAdminCompanies',
        variables: {},
      }),
      (oldData: GetApiAdminCompaniesResponse | undefined) => {
        if (!oldData) {
          return oldData;
        }
        return oldData.filter(
          (company) => company.externalId !== intacctCompanyId,
        );
      },
    );
  }, [intacctCompanyId, queryClient, resetForm]);

  const { data: jobStatusResponse, error: pollError } = useGetApiAdminJobStatus(
    {
      queryParams: {
        jobId: jobId!,
        queueName: 'delete-company-tree',
      },
      requestConfig: {
        skipToast: true,
      },
    },
    {
      enabled: jobId != null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: intervalMs,
      retry: (failureCount, error) => {
        if (error?.status === 404) {
          return false;
        }
        if (!(error instanceof Error)) {
          return false;
        }
        if (error.message?.includes('Too many requests') || failureCount < 5) {
          return true;
        }
        setError(error.message);
        return false;
      },
    },
  );

  useEffect(() => {
    if (pollError?.status === 404) {
      onSuccess();
    }
  }, [pollError?.status, onSuccess]);

  const progress = jobStatusResponse
    ? getProgress(jobStatusResponse?.progress)
    : 0;
  const approximateCompletionTime =
    jobStatusResponse?.estimatedCompletionTimestamp
      ? new Date(jobStatusResponse.estimatedCompletionTimestamp)
      : undefined;

  useEffect(() => {
    setConfirmationState('waiting');
    setCompanyData(undefined);
  }, [intacctCompanyId]);

  const scheduleDeleteCompany = async (data: DeleteCompanyForm) => {
    await mutation.mutateAsync(
      {
        pathParams: {
          id: data.intacctCompanyId,
        },
      },
      {
        onSuccess: (result) => {
          if (result.success) {
            toastr.success(
              I18njs.t('intacctAdmin.ScheduledDeleteMessage', {
                companyID: data.intacctCompanyId,
              }),
            );
            setJobId(result.jobId);
            setConfirmationState('waiting');
          } else {
            toastr.error(
              I18njs.t('intacctAdmin.UnsuccessfulDeleteMessage', {
                companyID: data.intacctCompanyId,
              }),
            );
          }
        },
        onError: (error) => {
          toastr.error(
            error instanceof Error ? error.message : (error as string),
          );
        },
      },
    );
  };

  const onSubmit: SubmitHandler<DeleteCompanyForm> = async (data) => {
    if (confirmationState === 'waiting') {
      setConfirmationState('fetching');
    } else if (confirmationState === 'fetched') {
      setConfirmationState('confirming');
    } else if (confirmationState === 'confirming') {
      await scheduleDeleteCompany(data);
    }
  };

  const saveButton =
    confirmationState === 'waiting' || confirmationState === 'fetching' ? (
      <Button
        buttonType="primary"
        type="submit"
        disabled={confirmationState === 'fetching'}
      >
        {isSubmitting ? (
          <Loader isActive isInsideButton />
        ) : (
          <Translate scope="Search" />
        )}
      </Button>
    ) : (
      <Button buttonType="primary" destructive type="submit">
        {isSubmitting ? (
          <Loader isActive isInsideButton />
        ) : (
          <Translate scope="Delete" />
        )}
      </Button>
    );

  return (
    <Form
      errorCount={errors.intacctCompanyId ? 1 : 0}
      onSubmit={handleSubmit(onSubmit)}
      saveButton={
        <CardRow>
          <CardColumn>{saveButton}</CardColumn>
        </CardRow>
      }
      leftSideButtons={
        <CardRow>
          <CardColumn>
            <Button buttonType="secondary" onClick={resetForm}>
              {I18njs.t('Clear')}
            </Button>
          </CardColumn>
        </CardRow>
      }
    >
      <Textbox
        {...intacctCompanyIdFields}
        label={I18njs.t('intacctAdmin.IntacctCompanyID')}
        required
        error={
          errors.intacctCompanyId?.type === 'required'
            ? I18njs.t('intacctAdmin.Thisfieldisrequired')
            : errors.intacctCompanyId?.message
        }
      />
      {companyData && (
        <>
          <Hr />
          <Message>
            <Text>Intacct company ID: {companyData.intacctCompanyId}</Text>
            <Text>Company tree ID: {companyData.companyTreeSettingsId}</Text>
            <Text>Tenant type: {companyData.tenantType || 'Not set'}</Text>
            {companyData.admins.length > 0 ? (
              companyData.admins.map((admin, index) => (
                <Text key={admin.username}>
                  Admin {index + 1}: {admin.username} ({admin.email})
                </Text>
              ))
            ) : (
              <Text>No admins</Text>
            )}
          </Message>
        </>
      )}
      {jobId != null && (
        <>
          <ProgressBar
            now={progress}
            approximateCompletionTime={approximateCompletionTime}
          />
          {!!error && (
            <Box mt="10px">
              <Message variant="error">{error}</Message>
            </Box>
          )}
        </>
      )}
      <Dialog
        onCancel={resetForm}
        title={I18njs.t('intacctAdmin.DeleteCompanyInstance')}
        open={confirmationState === 'confirming'}
      >
        <Box flexDirection="row" justifyContent="flex-end">
          <Translate
            scope="intacctAdmin.ConfirmDelete"
            markdown
            options={{
              intacctCompanyId: companyData?.intacctCompanyId,
            }}
          />
          <Hr />
          <div className="intacct-admin-modal-footer">
            <Button buttonType="primary" onClick={resetForm}>
              {I18njs.t('Cancel')}
            </Button>
            <Button
              buttonType="secondary"
              destructive
              onClick={handleSubmit(onSubmit)}
              ml={2}
            >
              {I18njs.t('Delete')}
            </Button>
          </div>
        </Box>
      </Dialog>
    </Form>
  );
}

export default DeleteInstanceForm;
