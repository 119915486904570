let backendOrigin = import.meta.env.VITE_APP_API_URL || '';

export function updateBackendOrigin(origin: string): void {
  backendOrigin = origin;
}

export const config = {
  isProduction: import.meta.env.VITE_APP_ENV === 'prod',
  get apiUrl() {
    return backendOrigin;
  },
  env: import.meta.env.VITE_APP_ENV || 'localhost',
  get samlUrl() {
    return (
      import.meta.env.VITE_APP_SAML_CALLBACK_URL ||
      `${backendOrigin}/auth/saml/login`
    );
  },
} as const;
