import Preview from 'carbon-react/lib/components/preview';

const PreviewComponent = () => {
  return (
    <div className="intacct-admin-container">
      <Preview height="722px" width="100%" loading />
    </div>
  );
};

export default PreviewComponent;
