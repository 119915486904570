import { initializeAuthCallbacks } from '@sit/client-shared';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function deleteCookies() {
  const Cookies = document.cookie.split(';');
  for (const cookie of Cookies) {
    document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`;
  }
}

export function useInitializeLogoutHandler() {
  const navigate = useNavigate();
  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate is not a dependency
  useEffect(() => {
    initializeAuthCallbacks({
      onLogout: () => {
        sessionStorage.clear();
        localStorage.clear();
        deleteCookies();
        navigate('/saml');
      },
    });
  }, []);
}
