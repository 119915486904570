import {
  type GetApiAdminCompanyByCompanyTreeSettingsIdResponse,
  useGetApiAdminTriggersPackages,
  usePostAdminSyncTriggersPackages,
} from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader';
import Textbox from 'carbon-react/lib/components/textbox';
import toastr from '../../../helpers/toastr';
import { useCompanyTreeSettingsIdInPathParams } from '../helpers';
import { CompanyTriggersForm } from './CompanyTriggersForm';

export interface CompanyUsersPageProps {
  company: GetApiAdminCompanyByCompanyTreeSettingsIdResponse;
}

export function CompanyTriggersPage() {
  const companyTreeSettingsId = useCompanyTreeSettingsIdInPathParams();
  const { mutate: syncTriggersPackages, isLoading: isSyncingVersion } =
    usePostAdminSyncTriggersPackages();
  const { data: triggersData, isLoading } = useGetApiAdminTriggersPackages({
    queryParams: {
      companyTreeSettingsId: `${companyTreeSettingsId}`,
    },
  });
  const currentTriggerVersion = triggersData?.packages?.find(
    (p) => p.companyTreeSettingsId === companyTreeSettingsId,
  )?.version;

  const onSync = () => {
    syncTriggersPackages(
      {
        pathParams: {
          companyTreeSettingsId: `${companyTreeSettingsId}`,
        },
      },
      {
        onSuccess: (data) => {
          toastr.success(
            `Latest triggers package version is ${
              data.triggerPackageVersion ?? 'not available'
            }`,
          );
        },
      },
    );
  };

  const packageVersionName = isLoading
    ? 'loading...'
    : (currentTriggerVersion ?? 'not available');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      p="1rem"
      gap={4}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignContent="flex-start"
        gap={2}
      >
        <Heading
          headingType="h2"
          title="Current trigger package version"
          divider={false}
        />
        <Box display="flex" flexDirection="row">
          <Box minWidth="150px">
            <Textbox value={packageVersionName} readOnly />
          </Box>
          <Button buttonType="primary" onClick={() => onSync()} size="small">
            {isSyncingVersion ? <Loader isInsideButton /> : 'Sync'}
          </Button>
        </Box>
      </Box>
      <Box>
        <CompanyTriggersForm companyTreeSettingsIds={[companyTreeSettingsId]} />
      </Box>
    </Box>
  );
}
