import {
  type AdminEntity,
  type GetApiAdminCompaniesResponse,
  usePatchAdminCompanyEntity,
} from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box/box.component';
import Button from 'carbon-react/lib/components/button';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableCheckbox,
  FlatTableHead,
  FlatTableHeader,
  FlatTableRow,
} from 'carbon-react/lib/components/flat-table';
import Icon from 'carbon-react/lib/components/icon';
import toastr from '../../../helpers/toastr';

export interface CompanyEntitiesTableProps {
  entities: AdminEntity[];
}

// only sync automatically if the entity has been accessed in the last two weeks or created in the last two weeks
const threshold = new Date(Date.now() - 1000 * 60 * 60 * 24 * 14).getTime();

const headDataItems: { id: HeaderItemId; name: string; info?: string }[] = [
  {
    id: 'id',
    name: 'SIT ID',
  },
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'lastAccessed',
    name: 'Last accessed',
  },
  {
    id: 'isCREEnabled',
    name: 'CRE enabled',
  },
  {
    id: 'isEmbeddedEnabled',
    name: 'Embedded enabled',
  },
  {
    id: 'deletedOnIntacct',
    name: 'Deleted on Intacct',
  },
  {
    id: 'markedForDeletion',
    name: 'Marked for deletion',
    info: 'If marked for deletion, the entity will be deleted on the next sync. `Deleted on Intacct` must be checked.',
  },
  {
    id: 'shouldSyncAutomatically',
    name: 'Auto sync enabled',
    info: 'Needs to be a production tenant type and accessed or created in the last two weeks to sync automatically.',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

type HeaderItemId =
  | keyof GetApiAdminCompaniesResponse[number]['entities'][number]
  | 'actions';

export function CompanyEntitiesTable({ entities }: CompanyEntitiesTableProps) {
  const { mutate: updateEntity } = usePatchAdminCompanyEntity();

  const handleUpdateEntity = (id: string) => {
    updateEntity({
      pathParams: {
        id,
      },
      body: {
        shouldSyncAutomatically: true,
      },
    });
  };

  const handleMarkForDeletion = (
    e: React.ChangeEvent<HTMLElement>,
    id: string,
  ) => {
    if ('checked' in e.target && typeof e.target.checked === 'boolean') {
      const markedForDeletion = e.target.checked;
      updateEntity(
        {
          pathParams: {
            id,
          },
          body: {
            markedForDeletion,
          },
        },
        {
          onSuccess: () => {
            toastr.info(
              `Entity marked for ${
                markedForDeletion ? 'deletion' : 'undeletion'
              }`,
            );
          },
        },
      );
    }
  };

  return (
    <>
      <Box width="100%">
        <FlatTable
          mt={2}
          colorTheme="transparent-white"
          hasStickyFooter
          hasStickyHead
          overflowX="auto"
          width="100%"
        >
          <FlatTableHead>
            <FlatTableRow>
              {headDataItems.map((dataItem) => (
                <FlatTableHeader key={dataItem.id}>
                  {dataItem.name}
                  {dataItem.info && (
                    <Icon
                      tooltipMessage={dataItem.info}
                      tooltipPosition="right"
                      type="help"
                    />
                  )}
                </FlatTableHeader>
              ))}
            </FlatTableRow>
          </FlatTableHead>
          <FlatTableBody>
            {entities.map((entity) => (
              <FlatTableRow key={entity.id}>
                <FlatTableCell>{entity.id}</FlatTableCell>
                <FlatTableCell>{entity.name}</FlatTableCell>
                <FlatTableCell>{entity.lastAccessed}</FlatTableCell>
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={entity.isCREEnabled}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={entity.isEmbeddedEnabled}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={entity.deletedOnIntacct}
                />
                <FlatTableCheckbox
                  onChange={(ev) => {
                    handleMarkForDeletion(ev, entity.id);
                  }}
                  selectable={entity.deletedOnIntacct}
                  checked={entity.markedForDeletion}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={entity.shouldSyncAutomatically}
                />
                <FlatTableCell>
                  {!entity.lastAccessed ||
                  new Date(entity.lastAccessed).getTime() < threshold ? (
                    <Button
                      size="small"
                      onClick={() => handleUpdateEntity(entity.id)}
                    >
                      Re-enable sync
                    </Button>
                  ) : null}
                </FlatTableCell>
              </FlatTableRow>
            ))}
          </FlatTableBody>
        </FlatTable>
      </Box>
    </>
  );
}
