import GoBackButton from '../../components/GoBackButton';
import ProvisionComponent from './Provision';

const Provision = () => {
  return (
    <div className="provision-container">
      <GoBackButton />
      <ProvisionComponent />
    </div>
  );
};

export default Provision;
