import {
  type GetApiAdminCompanyByCompanyTreeSettingsIdResponse,
  useGetCompanyById,
} from '@sit/client-shared';
import { Card, CardColumn, CardRow } from 'carbon-react/lib/components/card';
import Heading from 'carbon-react/lib/components/heading';
import Hr from 'carbon-react/lib/components/hr/hr.component';
import I18njs from 'i18n-js';
import styled from 'styled-components';
import { useCompanyTreeSettingsIdInPathParams } from '../helpers';
import { CompanyDetailsForm } from './CompanyDetailsForm';
import SyncStatusForm from './CompanySyncStatus';
import { DeactivateCompanyButton } from './DeactivateCompanyButton';
import { ReactivateCompanyButton } from './ReactivateCompanyButton';
import { UpdateWSUForm } from './UpdateWSUForm';

const FullWidthRoot = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 6rem;
`;

const CardHr = styled(Hr)`
  // I'd prefer not to do this, but it goes outside of the card otherwise
  max-width: 452px;
`;

const FirstGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px 16px;
  grid-auto-flow: row;
  grid-template-areas:
    'details sync .'
    'details activity .'
    '. . .';
`;

const DetailsGridArea = styled.div`
  grid-area: details;
`;

const SyncGridArea = styled.div`
  grid-area: sync;
`;

const ActivityGridArea = styled.div`
  grid-area: activity;
`;

export interface CompanyDetailsPageProps {
  company: GetApiAdminCompanyByCompanyTreeSettingsIdResponse;
}

// Known bug: when you reload the page, it performs the SAML authentication again.
// Upon re-navigating to this page at /companies/1, it instead navigates to /companies/:id.
// That's why there's a check in useCompanyTreeSettingsIdInPathParams to ensure that it's an integer.
export function CompanyDetailsPage() {
  const companyTreeSettingsId = useCompanyTreeSettingsIdInPathParams();
  const { data: company, isLoading } = useGetCompanyById({
    companyTreeSettingsId,
  });

  if (isLoading || !company) {
    return <FullWidthRoot>Loading...</FullWidthRoot>;
  }

  return (
    <FullWidthRoot>
      <FirstGrid>
        <DetailsGridArea>
          <Card>
            <CardRow>
              <CardColumn align="left">
                <Heading
                  mt={2}
                  title={I18njs.t('intacctAdmin.UpdateCompanyDetails')}
                />
              </CardColumn>
            </CardRow>
            <CompanyDetailsForm company={company} />
            <CardHr />
            <UpdateWSUForm intacctCompanyId={company.externalId} />
          </Card>
        </DetailsGridArea>
        <SyncGridArea>
          <Card>
            <CardRow>
              <CardColumn align="left">
                <Heading
                  title={I18njs.t('intacctAdmin.GetCompanySyncStatus')}
                  mt={2}
                />
              </CardColumn>
            </CardRow>
            <SyncStatusForm
              companyTreeSettingsId={companyTreeSettingsId}
              intacctCompanyId={company.externalId}
              dataSyncActive={company.dataSyncActive}
            />
          </Card>
        </SyncGridArea>
        <ActivityGridArea>
          <Card>
            <CardRow>
              <CardColumn align="left">
                <Heading title="Company activity" mt={2} />
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn align="right">
                <DeactivateCompanyButton
                  adminEmail={company.adminEmail!}
                  adminUsername={company.adminUsername!}
                  intacctCompanyId={company.externalId}
                />
                <ReactivateCompanyButton
                  adminEmail={company.adminEmail!}
                  adminUsername={company.adminUsername!}
                  intacctCompanyId={company.externalId}
                />
              </CardColumn>
            </CardRow>
          </Card>
        </ActivityGridArea>
      </FirstGrid>
    </FullWidthRoot>
  );
}
