import Button from 'carbon-react/lib/components/button';
import Dialog from 'carbon-react/lib/components/dialog';
import I18njs from 'i18n-js';
import { type FormEventHandler, useState } from 'react';
import Translate from '../../../components/Translate/Translate';
import { send } from '../../../helpers/requests';

export interface DeactivateCompanyButtonProps {
  intacctCompanyId: string;
  adminUsername: string;
  adminEmail: string;
}

export function DeactivateCompanyButton({
  adminEmail,
  adminUsername,
  intacctCompanyId,
}: DeactivateCompanyButtonProps) {
  const [requestSuccess, setRequestSuccess] = useState<boolean | null>(null);
  const handleSubmit: FormEventHandler<Element> = async (e) => {
    e.preventDefault();
    try {
      setRequestSuccess(false);
      await send({
        method: 'POST',
        url: '/admin/archive-company',
        data: {
          intacctCompanyId: intacctCompanyId,
          username: adminUsername,
          email: adminEmail,
        },
        credentials: 'include',
      });
      setRequestSuccess(true);
    } catch (error) {
      setRequestSuccess(false);
    }
  };

  const closeModal = () => setRequestSuccess(false);

  // TODO: Move the dialog to a separate component because it leaks when switching between tabs
  return (
    <>
      <Button buttonType="primary" onClick={handleSubmit} destructive>
        {I18njs.t('intacctAdmin.Deactivate')}
      </Button>
      <Dialog
        onCancel={closeModal}
        title={I18njs.t('intacctAdmin.Success')}
        open={!!requestSuccess}
      >
        <div>
          <Translate
            scope="intacctAdmin.SuccessfulDeactivationMessage"
            options={{ companyID: intacctCompanyId }}
            markdown
          />
        </div>
        <div className="intacct-admin-modal-footer">
          <Button buttonType="primary" onClick={closeModal}>
            OK
          </Button>
        </div>
      </Dialog>
    </>
  );
}
