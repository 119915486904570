import { ErrorClassification } from '@sit/client-shared';
import I18njs from 'i18n-js';
import toastr from './toastr';

export function updateNetworkStatus(isOnline: boolean) {
  if (!isOnline) {
    toastr.info(I18njs.t('network.info.internetRestored'), {
      toastId: 'API_NETWORK_INFO_ONLINE',
      dismissToastId: ErrorClassification.NETWORK_OFFLINE,
    });
  } else {
    toastr.error(I18njs.t('network.error.offline'), {
      toastId: ErrorClassification.NETWORK_OFFLINE,
      dismissToastId: 'API_NETWORK_INFO_ONLINE',
    });
  }
}

window.addEventListener('online', () => updateNetworkStatus(true));
window.addEventListener('offline', () => updateNetworkStatus(false));
