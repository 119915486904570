import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components';
import Translate from './Translate/Translate';

interface ProgressBarProps {
  now: number;
  approximateCompletionTime?: Date;
}

const ProgressBarWrapper = styled.div`
  background-color: transparent;
  border: 1px solid $border;
  margin: 50px 20px 20px;
  position: relative;
`;

const ProgressBarProgress = styled.div<{ now: number }>`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 40px;
  width: ${({ now }) => now}%;
`;

const Value = styled.div`
  font-size: 16px;
  font-weight: 700;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Remaining = styled.div`
  margin-bottom: 80px;
  text-align: center;
`;

export function ProgressBar({
  now,
  approximateCompletionTime,
}: ProgressBarProps) {
  const formattedApproximation = approximateCompletionTime
    ? formatDistanceToNow(approximateCompletionTime, { includeSeconds: true })
    : undefined;
  return (
    <>
      <ProgressBarWrapper>
        <ProgressBarProgress now={now} />
        <Value>{now || '0'}%</Value>
      </ProgressBarWrapper>
      {formattedApproximation && (
        <Remaining>
          <Translate
            scope="intacct.Approximatelyremaining"
            options={{ x: formattedApproximation }}
          />
        </Remaining>
      )}
    </>
  );
}
