import {
  type GetApiAdminCompanyUsersResponse,
  sortByBoolean,
  sortByDateString,
  sortByNumber,
  sortByString,
} from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box/box.component';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableCheckbox,
  FlatTableHead,
  FlatTableHeader,
  FlatTableRow,
  Sort,
} from 'carbon-react/lib/components/flat-table';
import Pager from 'carbon-react/lib/components/pager';
import Search from 'carbon-react/lib/components/search';
import type { SearchEvent } from 'carbon-react/lib/components/search/search.component';
import I18njs from 'i18n-js';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface CompanyUsersTableProps {
  users: GetApiAdminCompanyUsersResponse['users'];
}

const PAGE_SIZES = [
  {
    id: '10',
    name: 10,
  },
  {
    id: '20',
    name: 20,
  },
  {
    id: '50',
    name: 50,
  },
  {
    id: '100',
    name: 100,
  },
] as const;

const headDataItems: { id: HeaderItemId; name: string }[] = [
  {
    id: 'id',
    name: 'SIT ID',
  },
  {
    id: 'username',
    name: I18njs.t('intacctAdmin.UserId'),
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'role',
    name: 'SIT role',
  },
  {
    id: 'isArchived',
    name: 'Intacct inactive',
  },
  {
    id: 'isActive',
    name: 'SIT active',
  },
  {
    id: 'canListMyTimesheets',
    name: 'List my timesheets',
  },
  {
    id: 'canViewMyTimesheets',
    name: 'View my timesheets',
  },
  {
    id: 'hasStaffTimesheets',
    name: 'Staff timesheets',
  },
  {
    id: 'firstName',
    name: 'First name',
  },
  {
    id: 'lastName',
    name: 'Last name',
  },
  {
    id: 'locations',
    name: 'Locations',
  },
  {
    id: 'timezoneOffset',
    name: 'Timezone offset',
  },
  {
    id: 'lastLoginAttempt',
    name: 'Last login attempt',
  },
  {
    id: 'failedLoginAttempts',
    name: 'Failed login attempts',
  },
];

type HeaderItemId = keyof GetApiAdminCompanyUsersResponse['users'][number];

export function CompanyUsersTable({ users }: CompanyUsersTableProps) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortOrder, setSortOrder] = useState<
    'ascending' | 'descending' | undefined
  >('descending');
  const [recordsRange, setRecordsRange] = useState(() => ({
    start: 0,
    end: 5,
  }));
  const [sortedColumn, setSortedColumn] = useState<HeaderItemId>('username');

  const showInviteToken = users.some((user) => user.inviteToken != null);
  const showVerified = users.some((user) => user.isVerified === true);

  let headers = headDataItems;
  if (showInviteToken) {
    headers = [
      ...headers,
      { id: 'inviteToken', name: 'Invite Token' } as const,
    ];
  }
  if (showVerified) {
    headers = [...headers, { id: 'isVerified', name: 'Verified' } as const];
  }

  const handlePagination = (newPage: number, newPageSize: number) => {
    const adjustment = currentPage === newPage ? 0 : newPage - currentPage;
    let start =
      adjustment > 0
        ? recordsRange.start + newPageSize
        : recordsRange.start - newPageSize;
    if (newPageSize !== pageSize) {
      start =
        start < 0 || Math.abs(start - recordsRange.end) > newPageSize
          ? 0
          : start;
    }
    if (Math.abs(adjustment) > 1) {
      if (adjustment < 0)
        start = Math.max(start + 10 + adjustment * newPageSize, 0);
      else start = start - 10 + adjustment * newPageSize;
    }
    const end = start + newPageSize;
    setRecordsRange({
      start,
      end,
    });
    setCurrentPage(newPage);
    setPageSize(newPageSize);
  };
  const totalRecords = users.length;

  const handleSortClick = (value: HeaderItemId) => {
    setSortedColumn(value);
    setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const sortData = useCallback(
    (
      dataToSort: GetApiAdminCompanyUsersResponse['users'],
      sortedColumn: HeaderItemId,
    ) => {
      if (dataToSort.length === 0) return dataToSort;
      let sortedData = [...dataToSort];
      if (sortedColumn === 'lastLoginAttempt') {
        sortedData = sortByDateString(dataToSort, sortedColumn, sortOrder);
      }
      const firstNonNullValue = dataToSort.find(
        (item) => item[sortedColumn] != null,
      )?.[sortedColumn];
      if (typeof firstNonNullValue === 'string') {
        sortedData = sortByString(dataToSort, sortedColumn, sortOrder);
      }
      if (typeof firstNonNullValue === 'number') {
        sortedData = sortByNumber(dataToSort, sortedColumn, sortOrder);
      }
      if (typeof firstNonNullValue === 'boolean') {
        sortedData = sortByBoolean(dataToSort, sortedColumn, sortOrder);
      }
      return sortedData;
    },
    [sortOrder],
  );

  const onHighlight = (
    e: React.MouseEvent<HTMLElement>,
    id: number | string,
  ) => {
    navigate(`/users/${id}`);
  };

  // Pagination
  const filteredData = useMemo(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      return users.filter(
        (user) =>
          user.username.toLowerCase().includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower) ||
          user.id.includes(search) ||
          (user.firstName?.toLowerCase().includes(searchLower) ?? false) ||
          (user.lastName?.toLowerCase().includes(searchLower) ?? false),
      );
    }
    return users;
  }, [search, users]);
  const sortedData = useMemo(
    () => sortData(filteredData, sortedColumn),
    [sortData, filteredData, sortedColumn],
  );
  const pageData = sortedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const onChangeSearch = useCallback((ev: SearchEvent) => {
    setSearch(ev.target.value);
    setCurrentPage(1);
  }, []);

  return (
    <>
      <Box display="flex" width="100%" mt={2}>
        <Box flexGrow={1}>
          <Search
            onChange={onChangeSearch}
            value={search}
            searchButton
            placeholder="Search user info"
          />
        </Box>
      </Box>
      <Box width="100%">
        <FlatTable
          mt={2}
          colorTheme="transparent-white"
          hasStickyFooter
          hasStickyHead
          overflowX="auto"
          width="100%"
          footer={
            <Pager
              totalRecords={totalRecords}
              showPageSizeSelection
              pageSize={pageSize}
              currentPage={currentPage}
              onPagination={handlePagination}
              // @ts-expect-error -- OK that it's not mutable
              pageSizeSelectionOptions={PAGE_SIZES}
            />
          }
        >
          <FlatTableHead>
            <FlatTableRow>
              {headers.map((dataItem) => (
                <FlatTableHeader key={dataItem.id}>
                  <Sort
                    onClick={() => handleSortClick(dataItem.id)}
                    sortType={
                      dataItem.id === sortedColumn ? sortOrder : undefined
                    }
                  >
                    {dataItem.name}
                  </Sort>
                </FlatTableHeader>
              ))}
            </FlatTableRow>
          </FlatTableHead>
          <FlatTableBody>
            {pageData.map((user) => (
              <FlatTableRow
                key={user.id}
                onClick={(e) => onHighlight(e, user.id)}
              >
                <FlatTableCell>{user.id}</FlatTableCell>
                <FlatTableCell>{user.username}</FlatTableCell>
                <FlatTableCell>{user.email}</FlatTableCell>
                <FlatTableCell>{user.role}</FlatTableCell>
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={user.isArchived}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={user.isActive}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={user.canListMyTimesheets}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={user.canViewMyTimesheets}
                />
                {/** Empty onChange to avoid "You provided a `checked` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultChecked`. Otherwise, set either `onChange` or `readOnly`." */}
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={user.hasStaffTimesheets}
                />
                <FlatTableCell>{user.firstName}</FlatTableCell>
                <FlatTableCell>{user.lastName}</FlatTableCell>
                <FlatTableCell>{user.locations.join(', ')}</FlatTableCell>
                <FlatTableCell>{user.timezoneOffset}</FlatTableCell>
                <FlatTableCell>{user.lastLoginAttempt}</FlatTableCell>
                <FlatTableCell>{user.failedLoginAttempts}</FlatTableCell>
                {showInviteToken && (
                  <FlatTableCell>{user.inviteToken}</FlatTableCell>
                )}
                {showVerified && (
                  <FlatTableCheckbox
                    onChange={() => void 0}
                    checked={user.isVerified}
                  />
                )}
              </FlatTableRow>
            ))}
          </FlatTableBody>
        </FlatTable>
      </Box>
    </>
  );
}
