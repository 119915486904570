import {
  type PostApiAdminSyncTreeRequestBody,
  usePatchApiAdminCompany,
} from '@sit/client-shared';
import {
  ForceFullSyncObject,
  type ForceFullSyncObjectType,
  enumToArray,
} from '@sit/core';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import Heading from 'carbon-react/lib/components/heading';
import Hr from 'carbon-react/lib/components/hr/hr.component';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';
import I18njs from 'i18n-js';
import pull from 'lodash/pull';
import moment from 'moment';
import { type ChangeEvent, useState } from 'react';
import { FeatureFlag } from '../../../components/FeatureFlag';
import Translate from '../../../components/Translate/Translate';
import { send } from '../../../helpers/requests';
import toastr from '../../../helpers/toastr';
import { useSyncStatus } from '../../SyncStatus/useSyncStatus';

export interface SyncStatusFormProps {
  companyTreeSettingsId: number;
  intacctCompanyId: string;
  dataSyncActive: boolean;
}

function SyncStatusForm({
  companyTreeSettingsId,
  intacctCompanyId,
  dataSyncActive,
}: SyncStatusFormProps) {
  const [state, setState] = useState<{
    refetchData: boolean;
    requestError: string | null;
    syncError: string | null;
    syncStart: Date | null;
    syncEnd: Date | null;
    selectAllForceSyncObjects: boolean;
    forceSyncObjects: ForceFullSyncObjectType[];
  }>({
    refetchData: false,
    requestError: null,
    syncError: null,
    syncStart: null,
    syncEnd: null,
    selectAllForceSyncObjects: false,
    forceSyncObjects: [],
  });

  const [requestError, setRequestError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      setRequestError(null);
      setLoading(true);
      const data = { intacctCompanyId };
      await send({
        method: 'POST',
        url: '/admin/release-hung-sync',
        data,
        credentials: 'include',
      });
      toastr.success('Sync job released successfully');
    } catch (error) {
      setRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefetchAllDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const refetchData = e.target.checked;
    setState((prevState) => ({
      ...prevState,
      refetchData,
    }));
  };

  const handleSelectAllForceSyncObjectsChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const selectAllForceSyncObjects = e.target.checked;
    const forceSyncObjects = selectAllForceSyncObjects
      ? (enumToArray(ForceFullSyncObject) as ForceFullSyncObjectType[])
      : [];
    setState((prevState) => ({
      ...prevState,
      selectAllForceSyncObjects,
      forceSyncObjects,
    }));
  };

  const getForceFullSyncObjectChangeFunction =
    (obj: ForceFullSyncObjectType) => (e: ChangeEvent<HTMLInputElement>) => {
      const objectChecked = e.target.checked;
      const { forceSyncObjects } = state;
      if (objectChecked) {
        forceSyncObjects.push(obj);
      } else {
        pull(forceSyncObjects, obj);
      }
      setState((prevState) => ({
        ...prevState,
        forceSyncObjects,
      }));
    };

  const manualSync = async () => {
    try {
      const data: PostApiAdminSyncTreeRequestBody = {
        companyTreeSettingsId,
        refetchData: state.refetchData,
      };
      await send({
        method: 'POST',
        url: '/admin/sync-tree',
        data,
        credentials: 'include',
      });
      setState((prevState) => ({
        ...prevState,
        requestError: null,
        companyTreeSettingsId,
      }));
      toastr.success('Sync job submitted');
    } catch (error: unknown) {
      setState((prevState) => ({
        ...prevState,
        requestError: (error as Error).message,
        requestSuccess: false,
      }));
    }
  };

  const mutation = usePatchApiAdminCompany();

  const updateCompany = async () => {
    await mutation.mutateAsync(
      {
        body: {
          forceFullSync: state.forceSyncObjects,
        },
        pathParams: {
          id: `${companyTreeSettingsId}`,
        },
      },
      {
        onSuccess: () => {
          toastr.success(I18njs.t('intacctAdmin.Companyupdated'));
        },
        onError: (error) => {
          toastr.error(
            error instanceof Error ? error.message : (error as string),
          );
        },
      },
    );
  };

  const { data, progress } = useSyncStatus(companyTreeSettingsId, (error) => {
    setState((prevState) => ({
      ...prevState,
      requestError: error,
    }));
  });

  return (
    <>
      {data && (
        <div>
          <Checkbox
            readOnly
            label="Triggers enabled"
            checked={dataSyncActive}
          />
          <Hr />
          {!state.requestError && data.syncStart && !data.syncEnd && (
            <Message variant="info">
              <Translate
                scope="intacctAdmin.IntacctSyncRunning"
                options={{ syncStart: moment(data.syncStart).fromNow() }}
              />
            </Message>
          )}
          {!state.requestError && data.syncEnd && !(progress < 100) && (
            <Message variant="info">
              <Translate
                scope="intacctAdmin.IntacctSyncSuccess"
                options={{ syncEnd: moment(data.syncEnd).fromNow() }}
              />
            </Message>
          )}

          {progress < 100 && (
            <Message variant="info">
              <Translate
                scope="intacctAdmin.IntacctSyncProgress"
                options={{ progress }}
              />
            </Message>
          )}
        </div>
      )}
      {companyTreeSettingsId != null && (
        <div className="intacct-sync-status">
          <FeatureFlag featureName="ENABLE_ADMIN_REFETCH_ALL_DATA">
            <Hr />
            <Checkbox
              label={I18njs.t('intacctAdmin.IntacctRefetchAllData')}
              checked={state.refetchData}
              onChange={handleRefetchAllDataChange}
            />
          </FeatureFlag>
          <Box p={1} />
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Box mr={2}>
              <Button type="submit" onClick={handleSubmit}>
                {loading ? (
                  <Loader isActive isInsideButton />
                ) : (
                  <Translate scope="intacctAdmin.ReleaseHungSync" />
                )}
              </Button>
            </Box>
            <Box>
              <Button
                buttonType="primary"
                onClick={manualSync}
                disabled={progress ? progress < 100 : false}
              >
                <Translate scope="intacctAdmin.IntacctSyncManual" />
              </Button>
            </Box>
          </Box>
        </div>
      )}
      {(state.requestError || requestError) && (
        <Message variant="error">{state.requestError || requestError}</Message>
      )}
      <Hr />
      <Heading
        title="Force Full Sync"
        subheader="A full sync will be forced on the following objects during the next sync"
      />
      <Checkbox
        label={I18njs.t('intacctAdmin.SelectAll')}
        checked={state.selectAllForceSyncObjects}
        onChange={handleSelectAllForceSyncObjectsChange}
      />
      {enumToArray(ForceFullSyncObject).map((obj) => (
        <Checkbox
          key={obj}
          label={I18njs.t(`intacctAdmin.${obj}`)}
          checked={state.forceSyncObjects.includes(
            obj as ForceFullSyncObjectType,
          )}
          onChange={getForceFullSyncObjectChangeFunction(
            obj as ForceFullSyncObjectType,
          )}
        />
      ))}

      <CardRow>
        <CardColumn align="right">
          <Button buttonType="primary" onClick={updateCompany}>
            <Translate scope="intacctAdmin.Save" />
          </Button>
        </CardColumn>
      </CardRow>
    </>
  );
}

export default SyncStatusForm;
