import { usePatchAdminUserActiveStatus } from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Card, { CardColumn, CardRow } from 'carbon-react/lib/components/card';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import { GridContainer, GridItem } from 'carbon-react/lib/components/grid';
import Heading from 'carbon-react/lib/components/heading';
import Typography from 'carbon-react/lib/components/typography';
import { useParams } from 'react-router-dom';
import { useGetApiUser } from '../../api/use-get-api-user';
import GoBackButton from '../../components/GoBackButton';

function MainContainer({
  title,
  children,
}: { title: string; children: React.ReactNode }) {
  return (
    <div className="intacct-admin">
      <Box display="flex">
        <GoBackButton url="/companies" width="auto" />
        <Heading title={`Users - ${title}`} divider={false} />
      </Box>
      <div className="intacct-admin-inner-container">{children}</div>
    </div>
  );
}

export function UserPage() {
  const { id: userId } = useParams() as { id: string };
  const { data: userResponse, isLoading, isError } = useGetApiUser(userId);
  const { mutate: patchActiveStatus } = usePatchAdminUserActiveStatus();

  let title: string;
  if (isError) {
    title = 'User not found';
  } else if (isLoading || !userResponse) {
    title = 'Loading...';
  } else {
    title = userResponse.user.username;
  }

  const updateActiveStatus = (active: boolean) => {
    patchActiveStatus({ pathParams: { id: userId }, body: { active } });
  };

  if (!userResponse) {
    return <MainContainer title={title}>Loading...</MainContainer>;
  }
  const { user } = userResponse;

  const {
    canListMyTimesheets,
    canViewMyTimesheets,
    email,
    firstName,
    hasStaffTimesheets,
    isActive,
    isVerified,
    lastName,
    timezoneOffset,
    username,
  } = user;

  return (
    <MainContainer title={title}>
      <GridContainer>
        <GridItem justifySelf="center" gridColumn="1 / 2">
          <Card>
            <CardRow>
              <CardColumn align="left">
                <Heading title="User details" mt={2} />
              </CardColumn>
            </CardRow>
            <CardRow p={0}>
              <CardColumn align="left">
                <Box>
                  <Typography>
                    <strong>Username:</strong> {username}
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow p={0}>
              <CardColumn align="left">
                <Box>
                  <Typography>
                    <strong>First name:</strong> {firstName}
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow p={0}>
              <CardColumn align="left">
                <Box>
                  <Typography>
                    <strong>Last name:</strong> {lastName}
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow p={0}>
              <CardColumn align="left">
                <Box>
                  <Typography>
                    <strong>Email:</strong> {email}
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow p={0}>
              <CardColumn align="left">
                <Box>
                  <Typography>
                    <strong>Timezone offset:</strong> {timezoneOffset ?? 'N/A'}
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow py={1}>
              <CardColumn align="left">
                <Box>
                  <Checkbox
                    label="Can list my timesheets"
                    checked={canListMyTimesheets}
                  />
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow py={1}>
              <CardColumn align="left">
                <Box>
                  <Checkbox
                    label="Can view my timesheets"
                    checked={canViewMyTimesheets}
                  />
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow py={1}>
              <CardColumn align="left">
                <Box>
                  <Checkbox
                    label="Has staff timesheets"
                    checked={hasStaffTimesheets}
                  />
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow py={1}>
              <CardColumn align="left">
                <Box>
                  <Checkbox label="Is verified" checked={isVerified} />
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow pt={1}>
              <CardColumn align="left">
                <Box>
                  <Checkbox label="Is active" checked={isActive} />
                </Box>
              </CardColumn>
            </CardRow>
          </Card>
        </GridItem>
        <GridItem justifySelf="center" gridColumn="1 / 2">
          <Card>
            <CardRow>
              <CardColumn align="left">
                <Heading title="User active state" mt={2} />
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn align="left">
                <Box>
                  <Typography fontSize="16px">
                    An active user can log in to SIT, while an inactive user
                    cannot. This applies to both standalone and embedded users.
                  </Typography>
                </Box>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn align="left">
                <Box>
                  <Checkbox label="Is active" checked={isActive} />
                </Box>
              </CardColumn>
              <CardColumn align="left">
                <Box>
                  <Button onClick={() => updateActiveStatus(true)}>
                    Activate
                  </Button>
                </Box>
              </CardColumn>
              <CardColumn align="left">
                <Box>
                  <Button onClick={() => updateActiveStatus(false)} destructive>
                    Deactivate
                  </Button>
                </Box>
              </CardColumn>
            </CardRow>
          </Card>
        </GridItem>
      </GridContainer>
    </MainContainer>
  );
}
