import { StepFlow } from 'carbon-react/lib/components/step-flow';
import { type Steps, useStepNumber } from './steps';

export interface SequenceProps {
  step: (typeof Steps)[keyof typeof Steps];
}

export function Sequence({ step }: SequenceProps) {
  const { stepNumber, name } = useStepNumber(step);
  const currentStep = (stepNumber + 1) as 1 | 2 | 3;
  const total = 3;

  return (
    <StepFlow
      category="Provision company"
      title={name}
      currentStep={currentStep}
      totalSteps={total}
      showProgressIndicator={total !== currentStep}
    />
  );
}
