import {
  type GetApiAdminCompanyByCompanyTreeSettingsIdResponse,
  usePatchAdminCompany,
} from '@sit/client-shared';
import type { TenantType } from '@sit/core';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Form from 'carbon-react/lib/components/form/form.component';
import Loader from 'carbon-react/lib/components/loader';
import { Option, Select } from 'carbon-react/lib/components/select';
import Textbox from 'carbon-react/lib/components/textbox/textbox.component';
import I18njs from 'i18n-js';
import { type SubmitHandler, useController, useForm } from 'react-hook-form';
import Translate from '../../../components/Translate/Translate';
import toastr from '../../../helpers/toastr';

export interface CompanyDetailsProps {
  company: GetApiAdminCompanyByCompanyTreeSettingsIdResponse;
}

interface UpdateCompanyForm {
  externalId: string;
  tenantType?: TenantType;
  syncError?: string | null;
}

const emptyToNull = <T extends string | null | undefined>(value: T) => {
  return value === '' || value == null ? null : value;
};

export function CompanyDetailsForm({ company }: CompanyDetailsProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<UpdateCompanyForm>();

  const { field: tenantTypeField } = useController({
    name: 'tenantType',
    control,
    rules: {
      required: {
        value: true,
        message: I18njs.t('intacctAdmin.Thisfieldisrequired'),
      },
    },
    defaultValue: company.tenantType || undefined,
  });
  const { field: syncErrorField } = useController({
    name: 'syncError',
    control,
    defaultValue: company.syncError || '',
  });
  const { field: externalIdField } = useController({
    name: 'externalId',
    control,
    rules: {
      required: {
        value: true,
        message: I18njs.t('intacctAdmin.Thisfieldisrequired'),
      },
    },
    defaultValue: company.externalId,
  });

  const mutation = usePatchAdminCompany();

  const updateCompany = async (data: UpdateCompanyForm) => {
    await mutation.mutateAsync(
      {
        body: {
          externalId: data.externalId,
          tenantType: data.tenantType!,
          syncError: emptyToNull(data.syncError),
        },
        pathParams: {
          id: `${company.id}`,
        },
      },
      {
        onSuccess: () => {
          toastr.success(I18njs.t('intacctAdmin.Companyupdated'));
        },
        onError: (error) => {
          toastr.error(
            error instanceof Error ? error.message : (error as string),
          );
        },
      },
    );
  };

  const onSubmit: SubmitHandler<UpdateCompanyForm> = async (data) => {
    await updateCompany(data);
  };

  return (
    <Form
      errorCount={Object.values(errors).length}
      onSubmit={handleSubmit(onSubmit)}
      saveButton={
        <Button buttonType="primary" type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <Loader isActive isInsideButton />
          ) : (
            <Translate scope={'intacctAdmin.Save'} />
          )}
        </Button>
      }
    >
      <Textbox
        {...externalIdField}
        label={I18njs.t('intacctAdmin.CompanyId')}
        error={
          errors.externalId
            ? I18njs.t('intacctAdmin.Thisfieldisrequired')
            : undefined
        }
        required
      />
      <Textbox label="SIT ID" value={company.id} readOnly />
      <Textbox
        label="Admin username"
        value={company.adminUsername ?? ''}
        readOnly
      />
      <Textbox label="Admin email" value={company.adminEmail ?? ''} readOnly />
      <Textbox
        label="Last login attempt"
        value={company.lastLoginAttempt ?? ''}
        readOnly
      />
      <Textbox label="Sync start" value={company.syncStart ?? ''} readOnly />
      <Textbox label="Sync end" value={company.syncEnd ?? ''} readOnly />
      <Box display="flex">
        <Box flexGrow={1}>
          <Textbox
            {...syncErrorField}
            label="Sync error"
            value={syncErrorField.value ?? ''}
            readOnly
          />
        </Box>
        <Box mt="25px">
          <Button
            onClick={() => {
              syncErrorField.onChange('');
            }}
          >
            {I18njs.t('Clear')}
          </Button>
        </Box>
      </Box>
      <Select
        {...tenantTypeField}
        label={I18njs.t('provision.Pleaseselecttenanttype')}
        required
      >
        <Option value="customer_production" text="Customer Production" />
        <Option value="partner_production" text="Partner Production" />
        <Option value="SIAP_production" text="SIAP Production" />
        <Option value="MEDC_production" text="MEDC Production" />
        <Option value="sandbox" text="Sandbox" />
        <Option value="developer" text="Developer" />
        <Option value="sales_demo" text="Sales Demo" />
        <Option value="implementation" text="Implementation" />
        <Option value="debug" text="Debug" />
        <Option value="test" text="Test" />
        <Option value="preview" text="Preview" />
        <Option value="other" text="Other" />
      </Select>
    </Form>
  );
}
