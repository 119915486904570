import type { UserEntity } from '@sit/client-shared';
import { useCallback, useState } from 'react';
import LastStep from './LastStep';
import Step0WSUCredentials from './Step0WSUCredentials';
import Step1Sync from './Step1Sync';
import { Steps } from './steps';

function ProvisionTest() {
  const [step, setStep] = useState<(typeof Steps)[keyof typeof Steps]>(
    Steps.WSU_CREDENTIALS,
  );
  const [deferEntitySelection, setDeferEntitySelection] = useState(false);
  const [entities, setEntities] = useState<UserEntity[]>();
  const [companyTreeSettingsId, setCompanyTreeSettingsId] = useState<
    number | undefined
  >();

  const onSetWsuCredentialsSuccess = useCallback(
    ({
      entities,
      companyTreeSettingsId,
    }: {
      entities: UserEntity[];
      companyId: string;
      companyTreeSettingsId: number;
    }) => {
      setCompanyTreeSettingsId(companyTreeSettingsId);

      setEntities(entities);
      setStep(Steps.SYNC);
    },
    [],
  );

  const onSyncSuccess = useCallback(() => {
    setStep(Steps.LAST_STEP);
  }, []);

  const stepToRender = () => {
    switch (step) {
      case Steps.WSU_CREDENTIALS:
        return (
          <Step0WSUCredentials
            deferEntitySelection={deferEntitySelection}
            setDeferEntitySelection={setDeferEntitySelection}
            onSuccess={onSetWsuCredentialsSuccess}
          />
        );
      case Steps.SYNC: {
        const entityIds = entities?.map((e) => e.id) ?? [];
        return (
          <Step1Sync
            entityIds={entityIds}
            onSuccess={onSyncSuccess}
            companyTreeSettingsId={companyTreeSettingsId!}
          />
        );
      }
      case Steps.LAST_STEP:
        return <LastStep />;
    }
    return null;
  };

  return (
    <>
      <div className="provision-content">
        {
          // This is INTENTIONALLY not `<StepToRender />` because we want to render
          // the same component for multiple steps. This is because we want to
          // preserve the state of the component when the user goes back and forth
          // between steps or changes the value of `deferEntitySelection` during the WSU credentials step.
          stepToRender()
        }
      </div>
    </>
  );
}

export default ProvisionTest;
