import {
  ErrorClassification,
  type SessionStorageCallback,
  type SessionStorageCallbackWithResult,
  addAfterRetryErrorInterceptor,
  addBeforeRetryErrorInterceptor,
  axiosInstance,
  initializeSessionStorage,
  setBaseUrl,
} from '@sit/client-shared';
import { AxiosError } from 'axios';
import I18njs from 'i18n-js';
import { config } from '../config/env';
import { updateNetworkStatus } from './network-status';
import toastr from './toastr';

// Set the base URL for all axios requests.
setBaseUrl(config.apiUrl);

// `axiosInstance.defaults.withCredentials` is needed for the cookies to be sent with the request.
// Specifically, it adds `credentials: 'include'` to the request.
// The credentials read-only property of the Request interface indicates
// whether the user agent should send or receive cookies from the other domain in the case of cross-origin requests.
// See https://developer.mozilla.org/en-US/docs/Web/API/Request/credentials for more information.
axiosInstance.defaults.withCredentials = true;

// Add an interceptor to handle errors before retrying the request.
// In this case, the only error we want to handle is a network error.
addBeforeRetryErrorInterceptor((error) => {
  if (error.code === AxiosError.ERR_NETWORK) {
    if (navigator.onLine) {
      // server error, 500+
      toastr.error(I18njs.t('errors.messages.generic_request_error'), {
        toastId: ErrorClassification.NETWORK_SERVER_DOWN,
      });
    } else {
      updateNetworkStatus(false);
    }
    return Promise.reject(error);
  }
  return Promise.resolve();
});

// Add an interceptor to handle errors after retrying the request and have the error message
// in a consistent format.
// In this case, we want to (conditionally) toast the error message.
addAfterRetryErrorInterceptor((error) => {
  const shouldDisplayToast =
    !error.config.skipToast && error.code !== ErrorClassification.ERR_CANCELED;
  if (error.message === ErrorClassification.UNCLASSIFIED) {
    error.setMessage(I18njs.t('errors.messages.generic_error'));
  }

  if (shouldDisplayToast) {
    toastr.error(error.message, { toastId: error.config.toastId });
  }
  return Promise.resolve();
});

initializeSessionStorage({
  getItem: (
    key: string,
    cb: SessionStorageCallbackWithResult<string> | undefined,
  ) => {
    const result = sessionStorage.getItem(key);
    cb?.(null, result);
    return result;
  },
  setItem: (
    key: string,
    value: string,
    cb: SessionStorageCallback | undefined,
  ) => {
    sessionStorage.setItem(key, value);
    cb?.(null);
  },
  removeItem: (key: string, cb: SessionStorageCallback | undefined) => {
    sessionStorage.removeItem(key);
    cb?.(null);
  },
  clear: (cb: SessionStorageCallback | undefined) => {
    sessionStorage.clear();
    cb?.(null);
  },
});
