import { useGetApiAdminCompanies } from '@sit/client-shared';
import Heading from 'carbon-react/lib/components/heading';
import Loader from 'carbon-react/lib/components/loader/loader.component';
import { CompaniesTable } from './CompaniesTable';

export function CompaniesPage() {
  const { data: companies, isLoading } = useGetApiAdminCompanies({});

  return (
    <div className="intacct-admin">
      <Heading title="Companies" />
      <div className="intacct-admin-inner-container">
        {isLoading || !companies ? (
          <Loader />
        ) : (
          <CompaniesTable companies={companies} />
        )}
      </div>
    </div>
  );
}
