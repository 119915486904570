import { useNavigate, useParams } from 'react-router-dom';

export function useCompanyTreeSettingsIdInPathParams() {
  const navigate = useNavigate();

  const { id: companyTreeSettingsIdStr } = useParams<{ id: string }>();
  const companyTreeSettingsId = Number.parseInt(companyTreeSettingsIdStr!, 10);

  if (Number.isNaN(companyTreeSettingsId)) {
    navigate('/companies', { replace: true });
  }
  return companyTreeSettingsId;
}
