import { useGetAdminIsAuthenticated } from '@sit/openapi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';

const getInitialUrl = () => {
  const sessionItem = sessionStorage.getItem('saml_initial_url');
  return sessionItem || null;
};

const SamlCallback = () => {
  const navigate = useNavigate();

  useGetAdminIsAuthenticated();

  useEffect(() => {
    const initialUrl = getInitialUrl();
    if (initialUrl?.includes('saml')) {
      navigate('/intacct-admin');
    } else {
      navigate(initialUrl || '/intacct-admin');
    }
  }, [navigate]);

  return <Spinner />;
};

export default SamlCallback;
