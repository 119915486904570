import {
  useGetApiAdminScheduledJobs,
  usePostApiAdminScheduleJob,
} from '@sit/client-shared';
import Button from 'carbon-react/lib/components/button';
import {
  Card,
  CardColumn,
  CardFooter,
  CardRow,
} from 'carbon-react/lib/components/card';
import Heading from 'carbon-react/lib/components/heading/heading.component';
import Message from 'carbon-react/lib/components/message';
import { Option, Select } from 'carbon-react/lib/components/select';
import I18njs from 'i18n-js';
import { useState } from 'react';
import GoBackButton from '../../components/GoBackButton';
import Translate from '../../components/Translate/Translate';
import toastr from '../../helpers/toastr';

function RunAnyJobForm() {
  const [formError, setFormError] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);

  const { data: scheduledJobsData } = useGetApiAdminScheduledJobs({});
  const { mutate: scheduleJob, error: scheduleJobError } =
    usePostApiAdminScheduleJob();

  function runNow() {
    if (!selectedJob) {
      setFormError('Please select a job to run');
      return;
    }
    scheduleJob(
      {
        body: {
          queueName: selectedJob,
        },
      },
      {
        onSuccess: () => {
          setFormError(null);
          toastr.success('Job submitted');
        },
      },
    );
  }

  const handleJobSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedJob(e.target.value);
  };

  const errorMessage = formError ?? scheduleJobError?.message;

  return (
    <div className="intacct-admin-inner-container">
      <GoBackButton />
      <Card
        footer={
          <CardFooter>
            <CardColumn align="right">
              <Button buttonType="primary" onClick={runNow}>
                <Translate scope="intacctAdmin.RunNow" />
              </Button>
            </CardColumn>
            {errorMessage && <Message variant="error">{errorMessage}</Message>}
          </CardFooter>
        }
      >
        <CardRow>
          <CardColumn align="left">
            <Heading
              title={I18njs.t('intacctAdmin.BackgroundJobs')}
              subheader={
                <>
                  <b>WARNING:</b> this will run the job for all the companies!
                  <br /> Do not use in production unless you <b>REALLY</b> know
                  what you&#39;re doing!!!
                </>
              }
              mt={2}
            />
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn align="left">
            {scheduledJobsData && (
              <Select
                placeholder="Select a job to run"
                onChange={handleJobSelection}
              >
                {scheduledJobsData.scheduledJobs.map((job) => (
                  <Option
                    key={job.queueName}
                    value={job.queueName}
                    text={job.jobName}
                  />
                ))}
              </Select>
            )}
          </CardColumn>
        </CardRow>
      </Card>
    </div>
  );
}

export default RunAnyJobForm;
