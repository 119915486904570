import Button from 'carbon-react/lib/components/button';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../../assets/img/not-found.png';
import Translate from '../../components/Translate/Translate';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const goBackAction = () => {
    const hasHistory: boolean =
      window.history.state && window.history.state.idx > 0;
    if (hasHistory) navigate(-1);
    navigate('/');
  };

  return (
    <div className="not-found-page">
      <div className="left-content">
        <div className="title">
          <Translate scope="notFound.Sorrythispageisntavailable" />
        </div>
        <div className="subtitle">
          <Translate scope="notFound.Thelinkyoufollowed" />
        </div>
        <Button buttonType="primary" onClick={goBackAction}>
          <Translate scope="notFound.GoBack" />
        </Button>
      </div>
      <div className="right-content">
        <img src={NotFoundImage} alt="page not found asset" />
      </div>
    </div>
  );
};

export default NotFoundPage;
