import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableHead,
  FlatTableHeader,
  FlatTableRow,
} from 'carbon-react/lib/components/flat-table';
import toastr from '../../helpers/toastr';

const copyToClipboard = () => {
  const elTable = document.querySelector('table');
  let sel: Selection | null = null;
  // Ensure that range and selection are supported by the browsers
  if (elTable && document.createRange && window.getSelection) {
    const range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel?.removeAllRanges();
    try {
      range.selectNodeContents(elTable);
      sel?.addRange(range);
    } catch (error) {
      range.selectNode(elTable);
      sel?.addRange(range);
    }
    document.execCommand('copy');
  }
  sel?.removeAllRanges();
  toastr.success('Table copied to clipboard');
};

interface MissingPermissionsProps {
  missing: {
    module: string;
    activity: string;
    permissions: string[];
  }[];
}

function MissingPermissions(props: MissingPermissionsProps) {
  return (
    <>
      <Box mt={2}>
        <small>
          Please note that if you recently made changes to the permissions in
          Intacct, it might take up to 2 minutes for the changes to apply. If
          you get the same result after making changes, please try again after a
          minute.
        </small>
      </Box>
      <Box mt={2}>
        <b>The WSU is missing the following permissions:</b>
      </Box>
      <FlatTable size="small" mt={2}>
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>Application/Module</FlatTableHeader>
            <FlatTableHeader>Activities/Lists</FlatTableHeader>
            <FlatTableHeader>Required Permissions</FlatTableHeader>
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>
          {props.missing.map((missing) => (
            <FlatTableRow key={`${missing.module}-${missing.activity}`}>
              <FlatTableCell>{missing.module}</FlatTableCell>
              <FlatTableCell>{missing.activity}</FlatTableCell>
              <FlatTableCell>{missing.permissions.join(', ')}</FlatTableCell>
            </FlatTableRow>
          ))}
        </FlatTableBody>
      </FlatTable>
      <Box mt={2}>
        <Button onClick={() => copyToClipboard()}>Copy</Button>
      </Box>
    </>
  );
}

export default MissingPermissions;
