import type { FeatureFlags } from '@sit/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type AdminFeatureFlags = Pick<
  FeatureFlags,
  'ENABLE_ADMIN_REFETCH_ALL_DATA'
>;

export type AdminFeatureFlagNames = keyof AdminFeatureFlags;

export function useFlag<T extends AdminFeatureFlagNames>(featureName: T) {
  const flags = useFlags<AdminFeatureFlags>();
  return flags[featureName];
}
