import {
  type GetApiAdminCompanyByCompanyTreeSettingsIdResponse,
  useGetApiAdminCompanyUsers,
} from '@sit/client-shared';
import { useCompanyTreeSettingsIdInPathParams } from '../helpers';
import { CompanyUsersTable } from './CompanyUsersTable';

export interface CompanyUsersPageProps {
  company: GetApiAdminCompanyByCompanyTreeSettingsIdResponse;
}

export function CompanyUsersPage() {
  const companyTreeSettingsId = useCompanyTreeSettingsIdInPathParams();
  const { data: usersData, isLoading } = useGetApiAdminCompanyUsers({
    pathParams: {
      id: `${companyTreeSettingsId}`,
    },
  });

  if (isLoading || !usersData) {
    return <div>Loading...</div>;
  }

  return <CompanyUsersTable users={usersData.users} />;
}
