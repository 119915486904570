import I18njs from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import SamlContainer from './SamlContainer';

const SamlWrapper = () => {
  return (
    <ErrorBoundary>
      <Helmet>
        <title>{I18njs.t('helmet.SAML')}</title>
      </Helmet>
      <SamlContainer>
        <Outlet />
      </SamlContainer>
    </ErrorBoundary>
  );
};

export default SamlWrapper;
