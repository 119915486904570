import {
  type GetApiAdminCompaniesResponse,
  sortByBoolean,
  sortByDateString,
  sortByNumber,
  sortByString,
} from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box/box.component';
import Button from 'carbon-react/lib/components/button/button.component';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableCheckbox,
  FlatTableHead,
  FlatTableHeader,
  FlatTableRow,
  Sort,
} from 'carbon-react/lib/components/flat-table';
import Icon from 'carbon-react/lib/components/icon';
import Pager from 'carbon-react/lib/components/pager';
import Search from 'carbon-react/lib/components/search';
import type { SearchEvent } from 'carbon-react/lib/components/search/search.component';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createSumoLogicQueryLinkForCompany } from '../../helpers/sumologic';
import { BulkActions } from './BulkActions';
import { SyncTriggersPackageButton } from './SyncTriggersPackageButton';

const PAGE_SIZES = [
  {
    id: '10',
    name: 10,
  },
  {
    id: '20',
    name: 20,
  },
  {
    id: '50',
    name: 50,
  },
  {
    id: '100',
    name: 100,
  },
] as const;

export interface CompaniesTableProps {
  companies: GetApiAdminCompaniesResponse;
}

const headDataItems = [
  {
    id: 'selected',
    name: '',
    sortable: false,
  },
  {
    id: 'externalId',
    name: 'Company ID',
  },
  {
    id: 'id',
    name: 'SIT ID',
  },
  {
    id: 'tenantType',
    name: 'Tenant Type',
  },
  {
    id: 'entities',
    name: 'Entities',
    sortable: false,
  },
  {
    id: 'dataSyncActive',
    name: 'Triggers',
  },
  {
    id: 'lastLoginAttempt',
    name: 'Last Login Attempt',
  },
  {
    id: 'isImplemented',
    name: 'Implemented',
  },
  {
    id: 'isProvisioned',
    name: 'Provisioned',
  },
  {
    id: 'adminUsername',
    name: 'Admin Username',
  },
  {
    id: 'adminEmail',
    name: 'Admin Email',
  },
  {
    id: 'managedAtEntityLevel',
    name: 'Entity Level',
  },
  {
    id: 'syncStart',
    name: 'Sync Start',
  },
  {
    id: 'syncEnd',
    name: 'Sync End',
  },
  {
    id: 'syncError',
    name: 'Sync Error',
  },
  {
    id: 'triggerPackageVersion',
    name: 'Trigger package',
  },
] satisfies {
  id: HeaderItemId | 'selected';
  sortable?: boolean;
  name: string;
}[];

type HeaderItemId = keyof GetApiAdminCompaniesResponse[number];

export function CompaniesTable({ companies }: CompaniesTableProps) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortOrder, setSortOrder] = useState<
    'ascending' | 'descending' | undefined
  >('ascending');
  const [recordsRange, setRecordsRange] = useState(() => ({
    start: 0,
    end: 5,
  }));
  const [sortedColumn, setSortedColumn] = useState<HeaderItemId>('externalId');
  const [checkedRows, setCheckedRows] = useState<Record<number, boolean>>({});

  const handlePagination = (newPage: number, newPageSize: number) => {
    const adjustment = currentPage === newPage ? 0 : newPage - currentPage;
    let start =
      adjustment > 0
        ? recordsRange.start + newPageSize
        : recordsRange.start - newPageSize;
    if (newPageSize !== pageSize) {
      start =
        start < 0 || Math.abs(start - recordsRange.end) > newPageSize
          ? 0
          : start;
    }
    if (Math.abs(adjustment) > 1) {
      if (adjustment < 0)
        start = Math.max(start + 10 + adjustment * newPageSize, 0);
      else start = start - 10 + adjustment * newPageSize;
    }
    const end = start + newPageSize;
    setRecordsRange({
      start,
      end,
    });
    setCurrentPage(newPage);
    setPageSize(newPageSize);
  };
  const totalRecords = companies.length;

  const handleSortClick = (value: HeaderItemId) => {
    setSortedColumn(value);
    setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const sortData = useCallback(
    (dataToSort: GetApiAdminCompaniesResponse, sortedColumn: HeaderItemId) => {
      if (dataToSort.length === 0) return dataToSort;
      let sortedData = [...dataToSort];
      if (
        sortedColumn === 'lastLoginAttempt' ||
        sortedColumn === 'reactivatedOn' ||
        sortedColumn === 'syncEnd' ||
        sortedColumn === 'syncStart' ||
        sortedColumn === 'archivedOn'
      ) {
        sortedData = sortByDateString(dataToSort, sortedColumn, sortOrder);
      }
      const firstNonNullValue = dataToSort.find(
        (item) => item[sortedColumn] != null,
      )?.[sortedColumn];
      if (typeof firstNonNullValue === 'string') {
        sortedData = sortByString(dataToSort, sortedColumn, sortOrder);
      }
      if (typeof firstNonNullValue === 'number') {
        sortedData = sortByNumber(dataToSort, sortedColumn, sortOrder);
      }
      if (typeof firstNonNullValue === 'boolean') {
        sortedData = sortByBoolean(dataToSort, sortedColumn, sortOrder);
      }
      return sortedData;
    },
    [sortOrder],
  );

  const onHighlight = (
    e: React.MouseEvent<HTMLElement>,
    id: number | string,
  ) => {
    navigate(`/companies/${id}`);
  };

  // Pagination
  const filteredData = useMemo(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      const searchNumber = Number.parseInt(search, 10);
      const shouldSearchNumber = !Number.isNaN(searchNumber);
      const searchFilter = shouldSearchNumber
        ? (company: GetApiAdminCompaniesResponse[number]) =>
            company.externalId.toLowerCase().includes(searchLower) ||
            `${company.id}`.includes(search)
        : (company: GetApiAdminCompaniesResponse[number]) =>
            company.externalId.toLowerCase().includes(searchLower);
      // eslint-disable-next-line unicorn/no-array-callback-reference
      return companies.filter(searchFilter);
    }
    return companies;
  }, [search, companies]);
  const sortedData = useMemo(
    () => sortData(filteredData, sortedColumn),
    [sortData, filteredData, sortedColumn],
  );
  const pageData = sortedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const onChangeSearch = useCallback((ev: SearchEvent) => {
    setSearch(ev.target.value);
    setCurrentPage(1);
  }, []);

  return (
    <Box width="100%">
      <Box display="flex" width="100%">
        <Box flexGrow={1}>
          <Search
            onChange={onChangeSearch}
            value={search}
            searchButton
            placeholder="Search Company ID or SIT ID"
          />
        </Box>
        <Box ml={2}>
          <Link to="/provision">
            <Button>Provision company</Button>
          </Link>
        </Box>
      </Box>
      <Box width="100%">
        <FlatTable
          mt={2}
          colorTheme="transparent-white"
          hasStickyFooter
          hasStickyHead
          overflowX="auto"
          width="100%"
          footer={
            <Pager
              totalRecords={totalRecords}
              showPageSizeSelection
              pageSize={pageSize}
              currentPage={currentPage}
              onPagination={handlePagination}
              // @ts-expect-error -- OK that it's not mutable
              pageSizeSelectionOptions={PAGE_SIZES}
            />
          }
        >
          <FlatTableHead>
            <FlatTableRow>
              {headDataItems.map((dataItem) => (
                <FlatTableHeader key={dataItem.id}>
                  {dataItem.sortable === false ? (
                    dataItem.name
                  ) : (
                    <Sort
                      onClick={() => handleSortClick(dataItem.id)}
                      sortType={
                        dataItem.id === sortedColumn ? sortOrder : undefined
                      }
                    >
                      {dataItem.name}
                    </Sort>
                  )}
                </FlatTableHeader>
              ))}
              <FlatTableHeader>Actions</FlatTableHeader>
            </FlatTableRow>
          </FlatTableHead>
          <FlatTableBody>
            {pageData.map((company) => (
              <FlatTableRow
                key={company.id}
                onClick={(e) => onHighlight(e, company.id)}
              >
                <FlatTableCheckbox
                  onChange={(e) => {
                    e.stopPropagation();
                    setCheckedRows((rows) => ({
                      ...rows,
                      [company.id]: !rows[company.id],
                    }));
                  }}
                  checked={checkedRows[company.id]}
                />
                <FlatTableCell>{company.externalId}</FlatTableCell>
                <FlatTableCell>{company.id}</FlatTableCell>
                <FlatTableCell>{company.tenantType}</FlatTableCell>
                <FlatTableCell truncate width={300}>
                  {company.entities.map((e) => e.name).join(', ')}
                </FlatTableCell>
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={company.dataSyncActive}
                />
                <FlatTableCell>{company.lastLoginAttempt}</FlatTableCell>
                {/** Empty onChange to avoid "You provided a `checked` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultChecked`. Otherwise, set either `onChange` or `readOnly`." */}
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={company.isImplemented}
                />
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={company.isProvisioned}
                />
                <FlatTableCell>{company.adminUsername}</FlatTableCell>
                <FlatTableCell>{company.adminEmail}</FlatTableCell>
                <FlatTableCheckbox
                  onChange={() => void 0}
                  checked={company.managedAtEntityLevel}
                />
                <FlatTableCell>{company.syncStart}</FlatTableCell>
                <FlatTableCell>{company.syncEnd}</FlatTableCell>
                <FlatTableCell>{company.syncError}</FlatTableCell>
                <FlatTableCell>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {company.triggerPackageVersion ?? '-'}
                    <SyncTriggersPackageButton
                      companyTreeSettingsId={company.id}
                      externalId={company.externalId}
                    />
                  </Box>
                </FlatTableCell>
                <FlatTableCell>
                  <a
                    href={createSumoLogicQueryLinkForCompany(
                      company.externalId,
                    )}
                    target="_blank"
                    title={`View SumoLogic logs for ${company.externalId}`}
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Icon type="search" />
                  </a>
                </FlatTableCell>
              </FlatTableRow>
            ))}
          </FlatTableBody>
        </FlatTable>
      </Box>
      <BulkActions
        companyTreeSettingsIds={Object.entries(checkedRows)
          .filter(([, checked]) => checked)
          .map(([id]) => Number(id))}
      />
    </Box>
  );
}
