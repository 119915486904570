import en from './en';

// When you want to use a language other than English, you can add it here.
// However, you will need to add it to the `localesToKeep` array in the
// MomentLocalesPlugin in webpack.config.js.
const locales = {
  en,
};

export default locales;
