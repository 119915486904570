import {
  type LegacyRequestArguments,
  buildHeaders,
  onLogout,
} from '@sit/client-shared';
import i18njs from 'i18n-js';
import { config } from '../config/env';
import toastr from './toastr';

class ServerError extends Error {
  code: number;
  constructor(message: string, code: number) {
    super(message);
    this.code = code;
  }
}

const handleErrorString = (error: any, skipToast: boolean) => {
  let errObj: any;
  try {
    // Check if response is stringified object
    errObj = JSON.parse(error);
  } catch (error) {
    if (!skipToast) {
      toastr.error((error as Error).message);
    }
    throw error;
  }
  if (errObj.validation?.body?.message) {
    if (!skipToast) {
      toastr.error(errObj.validation.body.message);
    }
    throw new ServerError(
      errObj.validation.body.message,
      errObj.status || errObj.code,
    );
  }
  if (errObj.message) {
    if (
      errObj.message.includes('celebrate request validation failed') &&
      errObj.validation?.body?.message
    ) {
      if (!skipToast) {
        toastr.error(errObj.validation.body.message);
      }
      throw new ServerError(
        errObj.validation.body.message,
        errObj.status || errObj.code,
      );
    }
    if (!skipToast) {
      toastr.error(errObj.message);
    }
    throw new ServerError(errObj.message, errObj?.status || errObj?.code);
  } else if (errObj.errors) {
    const singleError = errObj.errors.join('\n');
    if (!skipToast) {
      toastr.error(singleError);
    }
    throw new ServerError(singleError, errObj?.status || errObj?.code);
  }
  const message = JSON.stringify(errObj);
  if (!skipToast) {
    toastr.error(message);
  }
  throw new ServerError(message, errObj?.status || errObj?.code);
};

const errorHandler = (error: any, skipToast: boolean) => {
  if (typeof error === 'string') {
    return handleErrorString(error, skipToast);
  }
  if (error.message === 'Failed to fetch') {
    if (navigator.onLine) {
      if (!skipToast) {
        toastr.error('Server unreachable');
      }
      throw new ServerError('Server unreachable', 400);
    }
    if (!skipToast) {
      toastr.error('No internet connection');
    }
    throw new ServerError('No internet connection', 400);
  }
  if (!skipToast) {
    toastr.error(error.message);
  }
  throw new ServerError(
    `Unrecognised error, please contact support. ${error.message}`,
    error.status || error.code,
  );
};

const constructUrl = (
  endpoint: string,
  params = {} as Record<string, string>,
): URL => {
  const url = new URL(`${config.apiUrl}${endpoint}`);

  if (params) {
    Object.keys(params).forEach((key) => {
      if (!params[key]) return;
      else url.searchParams.append(key, params[key]);
    });
  }

  return url;
};

const handleSuccessfulResponse = (res: Response): string | object => {
  const contentType = res.headers.get('content-type');
  if (contentType?.includes('application/json')) {
    return res.json();
  }
  return res.text();
};

const unauthorizedHandler = (
  _res: Response,
  _requestArguments: LegacyRequestArguments,
) => {
  onLogout?.();
};

const handleResponse = async (
  res: Response,
  requestArguments: LegacyRequestArguments,
) => {
  const { dontCheckForUnauthorized, skipToast } = requestArguments;

  // request succeeded
  if (res.ok) {
    return handleSuccessfulResponse(res);
  }

  // request failed
  const shouldHandleUnauthorized =
    !dontCheckForUnauthorized && res.status === 401;
  if (shouldHandleUnauthorized) {
    return unauthorizedHandler(res, requestArguments);
  }

  if (res.status >= 500) {
    return errorHandler(
      i18njs.t('errors.messages.generic_request_error'),
      !!skipToast,
    );
  }

  const responseErrorToText = await res.text();
  return errorHandler(responseErrorToText, !!skipToast);
};

const processChunkedResponse = (response: Response, skipToast: boolean) => {
  let text = '';
  const reader = response.body?.getReader();
  const decoder = new TextDecoder();

  function appendChunks(
    result: ReadableStreamReadResult<Uint8Array>,
  ): string | Promise<any> | undefined {
    const chunk = decoder.decode(result?.value || new Uint8Array(), {
      stream: !result.done,
    });
    text += chunk;
    if (result.done) {
      return text;
    } else {
      return readChunk();
    }
  }

  function readChunk() {
    return reader
      ?.read()
      .then(appendChunks)
      .catch((error) => {
        errorHandler(error, skipToast);
      });
  }

  return reader ? readChunk() : null;
};

const handleStreamedResponse = async (
  res: Response,
  requestArguments: LegacyRequestArguments,
) => {
  const { dontCheckForUnauthorized, skipToast } = requestArguments;

  // request succeeded
  if (res.ok) {
    let result = await processChunkedResponse(res, !!skipToast);
    try {
      result = JSON.parse(result);
    } catch (error) {
      // ignore
    }
    return result;
  }

  // request failed
  const shouldHandleUnauthorized =
    !dontCheckForUnauthorized && res.status === 401;
  if (shouldHandleUnauthorized) {
    return unauthorizedHandler(res, requestArguments);
  }

  if (res.status >= 500) {
    return errorHandler(
      i18njs.t('errors.messages.generic_request_error'),
      !!skipToast,
    );
  }

  const responseErrorToText = await res.text();
  return errorHandler(responseErrorToText, !!skipToast);
};

const send = async (requestArguments: LegacyRequestArguments): Promise<any> => {
  const {
    method,
    url,
    data,
    params,
    token = null,
    headers,
    credentials = 'include',
    skipToast,
    cache = 'no-cache',
    useStream,
  } = requestArguments;
  try {
    const finalHeaders = buildHeaders(headers, token);
    const fullUrl = constructUrl(url, params);

    const res = await fetch(fullUrl.toString(), {
      body: JSON.stringify(data),
      cache,
      headers: finalHeaders,
      method,
      credentials,
    });

    if (useStream) {
      return await handleStreamedResponse(res, requestArguments);
    } else {
      return await handleResponse(res, requestArguments);
    }
  } catch (error) {
    return errorHandler(error, !!skipToast);
  }
};

export { send };
