import { loadingChunkRetry } from '@sit/client-shared';
import I18njs from 'i18n-js';
import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../../components/ErrorBoundary';
import PreviewComponent from './PreviewComponent';

const IntacctAdmin = lazy(() =>
  loadingChunkRetry(() => import('./IntacctAdmin')),
);

const IntacctAdminWrapper = () => {
  return (
    <ErrorBoundary>
      <Helmet>
        <title>{I18njs.t('helmet.InternalAdminDashboard')}</title>
      </Helmet>
      <Suspense fallback={<PreviewComponent />}>
        <IntacctAdmin />
      </Suspense>
    </ErrorBoundary>
  );
};

export default IntacctAdminWrapper;
