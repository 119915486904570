import type { ProviderConfig } from 'launchdarkly-react-client-sdk';

function buildLaunchDarklyConfig({
  clientSideID,
  disabled = false,
}: { clientSideID: string; disabled: boolean }): ProviderConfig {
  const enabled = !disabled;
  const config: ProviderConfig = {
    clientSideID,
    deferInitialization: false,
    options: {
      fetchGoals: enabled,
      streaming: enabled,
      sendEvents: enabled,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  };
  return config;
}

export default buildLaunchDarklyConfig;
